import { combineReducers } from 'redux';
import { UPDATE_EVENT_SAVED_BY_YOU } from '../actions/event';
import {
    SUGGESTIONS_LOADING,
    UPDATE_SEARCH_SUGGESTIONS,
} from '../actions/search';

const query = (
    state = [],
    { type, payload }: { type: string; payload?: any },
) => {
    let nextState = state;

    if (type === UPDATE_SEARCH_SUGGESTIONS && payload.query) {
        nextState = payload.query;
    }

    return nextState;
};

const event = (
    state = [],
    { type, payload }: { type: string; payload?: any },
) => {
    let nextState = state;

    if (type === UPDATE_SEARCH_SUGGESTIONS && payload.event) {
        nextState = payload.event;
    }

    return nextState;
};

const tags = (
    state = [],
    { type, payload }: { type: string; payload?: any },
) => {
    let nextState = state;

    if (type === UPDATE_SEARCH_SUGGESTIONS && payload.tags) {
        nextState = payload.tags;
    }

    return nextState;
};

const filters = (
    state = [],
    { type, payload }: { type: string; payload?: any },
) => {
    let nextState = state;

    if (type === UPDATE_SEARCH_SUGGESTIONS && payload.filters) {
        nextState = payload.filters;
    }

    return nextState;
};

const events = (
    state = [],
    { type, payload }: { type: string; payload?: any },
) => {
    let nextState = state;

    if (type === UPDATE_SEARCH_SUGGESTIONS && payload.events) {
        nextState = payload.events;
    }

    return nextState;
};

const rawEvents = (
    state = [],
    { type, payload }: { type: string; payload?: any },
) => {
    let nextState: { [key: string]: any } = state;

    if (type === UPDATE_SEARCH_SUGGESTIONS && payload.rawEvents) {
        nextState = payload.rawEvents;
    }

    if (type === UPDATE_EVENT_SAVED_BY_YOU) {
        const { eventId, savedByYou } = payload;

        nextState = {
            ...nextState,
            [eventId]: {
                ...nextState[eventId],
                saves: {
                    savedByYou,
                },
            },
        };
    }

    return nextState;
};

const history = (
    state = [],
    { type, payload }: { type: string; payload?: any },
) => {
    let nextState = state;

    if (type === UPDATE_SEARCH_SUGGESTIONS && payload.history) {
        nextState = payload.history;
    }

    return nextState;
};

const loading = (state = true, { type }: { type: string }) => {
    let nextState = state;

    if (type === UPDATE_SEARCH_SUGGESTIONS) {
        nextState = false;
    }

    if (type === SUGGESTIONS_LOADING) {
        nextState = true;
    }

    return nextState;
};

export default combineReducers({
    query,
    event,
    tags,
    events,
    rawEvents,
    history,
    loading,
    filters,
});
