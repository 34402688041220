import { isCategory, isSubCategory } from '@eventbrite/categories';
import {
    CURRENT_FUTURE,
    EventSearch,
    FORMAT_TAG_TYPE,
} from '@eventbrite/discover-utils';
import compact from 'lodash/compact';
import { SEARCH_RADIUS_DEFAULTS } from '../../../constants';
import { updateSearchCriteria } from '../../search';
import { isTagType } from '../../utils/tags';

interface handleRemoveFilterProps {
    price?: string;
    dates?: string[];
    category?: boolean;
    subcategories?: boolean;
    format?: boolean;
    currencies?: string;
    languages?: string;
    special?: string;
    organizationsOr?: string[];
    hash?: string;
    places?: string[];
    distance?: string;
    experiences?: string[];
}

export const handleRemoveFilter =
    ({
        category,
        subcategories,
        price,
        dates,
        format,
        currencies,
        languages,
        special,
        organizationsOr,
        hash,
        distance,
        experiences,
    }: handleRemoveFilterProps) =>
    (dispatch: Function, getState: Function) => {
        const {
            search: { eventSearch },
            location,
        } = getState();

        const updatedTags = getUpdatedTagsForRemoval(eventSearch.tags, {
            category,
            subcategories,
            format,
        });

        const searchQuery = {
            ...eventSearch,
            tags: updatedTags,
            price: price ? '' : eventSearch.price,
            dates: dates ? CURRENT_FUTURE : eventSearch.dates,
            dateRange: dates ? {} : eventSearch.dateRange,
            currencies: currencies ? '' : eventSearch.currencies,
            languages: languages ? '' : eventSearch.languages,
            organizationsOr: organizationsOr ? [] : eventSearch.organizationsOr,
            hash: hash ? '' : eventSearch.hash,
            special: special ? [] : eventSearch.special,
            page: 1,
            pointRadius: distance
                ? getClearDistanceFilter(location, eventSearch)
                : eventSearch.pointRadius,
            experiences: experiences ? [] : eventSearch.experiences,
        };

        dispatch(updateSearchCriteria(searchQuery));
    };

const getClearDistanceFilter = (location: any, eventSearch: EventSearch) => {
    const pointRadius = eventSearch.pointRadius;

    //Distance filter will only be cleared if the user is enrolled
    //in the experiment. Given that, if they are sharing their location
    //with us that means the pointRadius filter is holding onto the
    //user's current location.
    const isUsingCurrentLocation = location.isUsingCurrentLocation;

    //If user is sharing their current location remove
    //the distance filter specific radius and reapply
    //the offset/scale to normalize ranking with a standard
    //search.
    if (isUsingCurrentLocation) {
        return {
            ...pointRadius,
            ...SEARCH_RADIUS_DEFAULTS,
        };
    }

    //Handle case where the previous "place" is missing due to
    //an unfound place or other reason, we still want the search
    //to work as expected. So if there is not placeId representation
    //in the location reducer and it is not a map based search
    //we will want to keep using the lat/lng but with a standardized distance
    if (!location.placeId && !eventSearch.bbox && !location.isOnline) {
        return {
            ...pointRadius,
            ...SEARCH_RADIUS_DEFAULTS,
        };
    }

    return {};
};

const getUpdatedTagsForRemoval = (
    currentTags: string[],
    { subcategories = false, category = false, format = false },
) => {
    // This approach assues there will only ever be at most one of each type
    // If that is to change than the logic here will need to be updated
    return compact(
        currentTags.map((currentTag: string) => {
            if (
                (category && isCategory(currentTag)) ||
                (format && isTagType(currentTag, FORMAT_TAG_TYPE)) ||
                (subcategories && isSubCategory(currentTag))
            ) {
                return '';
            }

            return currentTag;
        }),
    );
};
