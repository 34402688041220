import { GenericLazyString } from '@eventbrite/i18n';
import { FocusedEventCardsType } from '../reducers/ui';

export const SET_HOVER_EVENT_TARGET = 'search/eventCard/hover';
export const setHoverEventTarget = (eid: string) => ({
    type: SET_HOVER_EVENT_TARGET,
    payload: eid,
});

export const HIDE_ERROR_NOTIFICATION = 'search/notification/error/hide';
export const SHOW_ERROR_NOTIFICATION = 'search/notification/error/show';
export const showErrorNotification =
    (message: string | GenericLazyString) => (dispatch: Function) => {
        setTimeout(() => {
            dispatch(hideErrorNotification());
        }, 5000);
        dispatch({ type: SHOW_ERROR_NOTIFICATION, payload: message });
    };
export const hideErrorNotification = () => ({
    type: HIDE_ERROR_NOTIFICATION,
});

export const SET_MAP_PIN_HOVER_TARGET = 'search/mapPin/hover';
export const setMapPinHoverTarget = (ids: FocusedEventCardsType) => ({
    type: SET_MAP_PIN_HOVER_TARGET,
    payload: ids,
});
export const SET_MAP_PIN_CLICK_TARGET = 'search/mapPin/click';
export const setMapPinClickTarget = (ids: FocusedEventCardsType) => ({
    type: SET_MAP_PIN_CLICK_TARGET,
    payload: ids,
});
