import { combineReducers } from 'redux';
import { AppState } from '../../types';
import { SET_IS_LOADING } from '../actions/page';

const isLoading = (
    state = false,
    { type, payload }: { type: string; payload?: any },
) => {
    let nextState = state;

    if (type === SET_IS_LOADING) {
        nextState = payload;
    }

    return nextState;
};

export default combineReducers<AppState>({
    isLoading,
    publicId: (state = '') => state,
    sessionId: (state = '') => state,
    guestId: (state = '') => state,
    isBotRequest: (state = false) => state,
    countryCode: (state = '') => state,
    googleMapsLanguage: (state = '') => state,
    httpReferer: (state = '') => state,
    domain: (state = '') => state,
    featureFlags: (state = {}) => state,
    experiments: (state = {}) => state,
    serverUrl: (state = '') => state,
    tld: (state = '.com') => state,
    userEmail: (state = '') => state,
    locale: (state = '') => state,
    languageCode: (state = 'en') => state,
    isGDPRCountry: (state = true) => state,
    options: (state = {}) => state,
    country_popular_cities: (state = {}) => state,
    uses_language_subdirectory: (state = false) => state,
});
