import { EventSearch } from '@eventbrite/discover-utils';
import {
    OrganicEventSearchApiResponse,
    OrganicEventSearchFiltersApiPayload,
} from '../../api/searchOrganicEvents';
import { parseSearchCriteria } from '../../utils/transformation';

const initialEventSearch: EventSearch = {
    q: '',
    dates: '',
    dateRange: {},
    durationRange: {},
    organizationsOr: [],
    dedup: false,
    experiences: [],
    hash: '',
    places: [],
    price: '',
    tags: [],
    page: 1,
    pageSize: 20,
    bbox: '',
    source: [],
    aggs: {},
    onlineEventsOnly: false,
    languages: [],
    currencies: [],
    clientTimezone: '',
    special: [],
    pointRadius: {},
    includePromotedEventsFor: null,
};

export function getInitialSearchCriteria(
    searchData: OrganicEventSearchApiResponse | undefined,
): OrganicEventSearchFiltersApiPayload {
    return {
        ...initialEventSearch,
        ...parseSearchCriteria(searchData?.event_search || {}),
    };
}
