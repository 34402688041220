import { type GenericLazyString } from '@eventbrite/i18n';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import { BreadCrumbListItemContent } from './BreadCrumbListItemContent';
import { BreadCrumbListItemSeparator } from './BreadCrumbListItemSeparator';
import styles from './BreadCrumbs.module.scss';
import { BreadCrumbItem, ExtendedBreadCrumbItem } from './types';

export interface BreadCrumbsProps<T> {
    /**
     * Array of BreadCrumbItem objects that
     * define display and value for the
     * particular BreadCrumb
     */
    items: BreadCrumbItem<T>[];
    /**
     * To use the component the item is passed back to
     * the caller via the renderProp pattern.
     *
     * The consumer can then define whatever wrapper they
     * prefer.
     */
    children: (item: ExtendedBreadCrumbItem<T>) => React.ReactElement;
    /**
     * Classes to be applied to the wrapping container
     */
    containerClasses?: string;
    /**
     * Classes to be applied to the nav element
     */
    navClasses?: string;
    /**
     * Node to be used to separate the breadcrumb
     * elements.
     */
    separator?: React.ReactElement | string | GenericLazyString;
    /**
     * Should we include MicroData Schema Markup
     * on these breadcrumbs
     *
     * Defaults: false
     */
    shouldIncludeMicroData?: boolean;
    /**
     * Aria label for the breadcrumbs
     */
    ariaLabel?: string | GenericLazyString;
}

/**
 * BreadCrumb component that includes a11y friendly markup
 * and microdata schema support.
 *
 * To include microdata support in your rendered content
 * use the extended params included with each item returned
 * from the render prop
 *
 * eg:
 * ```
 * <BreadCrumbs items={items}>
 *      {(item) => (
 *          <a href={item.value} {...item.valueMicrodataProps}>
 *              {item.displayWithMicroData}
 *          </a>
 *      )}
 *  </BreadCrumbs>
 *```

 * @param BreadCrumbsProps
 * @returns
 */
export function BreadCrumbs<T>(props: BreadCrumbsProps<T>) {
    const {
        items,
        navClasses = 'discover-breadcrumbs',
        containerClasses,
        separator = '/',
        shouldIncludeMicroData,
        ariaLabel = 'breadcrumbs',
    } = props;
    const breadcrumbsRef = useRef(
        null,
    ) as MutableRefObject<HTMLOListElement | null>;

    // Scroll breadcrumbs to show current page in view
    useEffect(() => {
        const breadcrumbs = breadcrumbsRef.current;
        if (breadcrumbs) {
            breadcrumbs.scrollLeft += breadcrumbs.scrollWidth;
        }
    }, []);

    return (
        <nav
            aria-label={ariaLabel.toString()}
            className={`${navClasses} ${styles['discover-breadcrumbs']}`}
            data-spec="breadcrumbs"
            data-testid="breadcrumb-nav"
        >
            <ol
                className={`${containerClasses} breadcrumb-container ${styles['breadcrumb-container']}`}
                data-testid="breadcrumb-ol"
                {...(shouldIncludeMicroData
                    ? {
                          itemScope: true,
                          itemType: 'https://schema.org/BreadcrumbList',
                      }
                    : {})}
                ref={breadcrumbsRef}
            >
                {items.map((item, index) => {
                    const renderSeparator =
                        items.length > 1 && index < items.length - 1;
                    const isLast = index === items.length - 1;

                    return (
                        <li
                            key={item.value}
                            data-testid="breadcrumb-element"
                            {...(shouldIncludeMicroData
                                ? {
                                      itemProp: 'itemListElement',
                                      itemType: 'https://schema.org/ListItem',
                                      itemScope: true,
                                  }
                                : {})}
                        >
                            <>
                                <BreadCrumbListItemContent
                                    isLast={isLast}
                                    display={item.display}
                                    shouldIncludeMicroData={
                                        !!shouldIncludeMicroData
                                    }
                                >
                                    {props.children({
                                        ...item,
                                        valueMicrodataProps: {
                                            itemProp: 'item',
                                        },
                                        displayWithMicroData: (
                                            <span itemProp="name">
                                                {item.display}
                                            </span>
                                        ),
                                    })}
                                </BreadCrumbListItemContent>
                                {shouldIncludeMicroData ? (
                                    <meta
                                        itemProp="position"
                                        content={`${index + 1}`}
                                    />
                                ) : null}
                                <BreadCrumbListItemSeparator
                                    renderSeparator={renderSeparator}
                                >
                                    {separator}
                                </BreadCrumbListItemSeparator>
                            </>
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
}
