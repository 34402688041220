import { updatePageProps } from '@eventbrite/datalayer-library';
import { type EventSearch } from '@eventbrite/discover-utils';
import { FormattedEvent } from '@eventbrite/event-renderer';
import { CLEAN_TRACKING_DATA } from '@eventbrite/search-utils';
import { trackEventFromState } from '@eventbrite/site-analytics';
import { logEvent } from '@eventbrite/statsig';
import isEmpty from 'lodash/isEmpty';
import {
    GA_ENTER_MAP_VIEW,
    GA_PAGE_LOAD_ACTION,
    GA_RUN_SEARCH_ACTION,
    GA_SEARCH_CATEGORY,
    HEAP_CITY_BROWSE_PAGE_VIEW,
    HEAP_SEARCH_PAGE_VIEW,
    SEARCH_EVENT_NAVIGATION,
} from '../../constants/analytics';
import { getSearchId } from '../../pages/search/utils/analytics';
import { DiscoverState, PaginationData } from '../../types';
import { logDiscoveryRequest } from '../api/analytics';
import {
    trackInternalAppClick,
    transformSearchAndDiscovery,
} from '../utils/analytics';

const MAX_EVENTS_FOR_GA = 10;
const processTagsForGA = (tagType: string, eventSearch: { tags?: string[] }) =>
    eventSearch.tags && eventSearch.tags.length
        ? eventSearch.tags.filter((tag) => tag.indexOf(tagType) > -1).join(',')
        : '';

const processDatesForCustomDimension = (eventSearch: {
    dateRange?: { from?: string; to?: string };
    dates?: string[];
}) => {
    if (
        eventSearch.dateRange &&
        eventSearch.dateRange.from &&
        eventSearch.dateRange.to
    ) {
        return `${eventSearch.dateRange.from},${eventSearch.dateRange.to}`;
    }

    if (Array.isArray(eventSearch.dates)) {
        return eventSearch.dates[1];
    }

    return '';
};

// There are some dimensions we want on all Search pageviews in GA
// The definitions come from here:
// https://analytics.google.com/analytics/web/#/a141520w171290p95297140/admin/custom-dimensions/m-content-dimensionsContent.rowShow=100&m-content-dimensionsContent.rowStart=0&m-content-dimensionsContent.sortColumnId=index&m-content-dimensionsContent.sortDescending=false
// The constants map to dimensions here:
// https://github.com/eventbrite/js-utils/blob/master/src/analytics/constants.js
export const getSearchCustomDimensionsAndMetrics = (
    eventSearch: Pick<
        EventSearch,
        'bbox' | 'q' | 'price' | 'page' | 'tags' | 'dateRange' | 'dates'
    >,
    slug: any,
    eventIds: any[],
) => ({
    searchLocation: eventSearch.bbox || slug,
    // first half of events (due to GA action size limits, we can only send a max of 10 event IDs per dimension field)
    searchEventIds1: eventIds.slice(0, MAX_EVENTS_FOR_GA).join(','),
    // second half of events - right now we assume that there are 20 events on the page,
    // if we change it more, we will need more dimensions to accommodate it
    searchEventIds2: eventIds
        .slice(MAX_EVENTS_FOR_GA, MAX_EVENTS_FOR_GA * 2)
        .join(','),
    searchQuery: eventSearch.q,
    searchDates: processDatesForCustomDimension(eventSearch),
    searchPrice: eventSearch.price || '',
    searchCategory: processTagsForGA('EventbriteCategory', eventSearch),
    searchFormat: processTagsForGA('EventbriteFormat', eventSearch),
    searchSort: '',
    searchResultCount: eventIds.length,
    searchPagination: eventSearch.page,
});

export const trackEventClickInternal =
    (data: any) =>
    (
        _dispatch: any,
        getState: () => {
            search: { searchId: any; eventSearch: any };
            app: { guestId: any };
        },
    ) => {
        const {
            search: { searchId, eventSearch },
            app: { guestId },
        } = getState();

        const searchMetricId = getSearchId({ eventSearch, guestId });

        trackInternalAppClick({ searchId, searchMetricId, guestId, ...data });
    };

export const trackCityBrowsePageView =
    () => (_dispatch: any, _getState: Function) => {
        logEvent(HEAP_CITY_BROWSE_PAGE_VIEW);
    };

/*
When a page is viewed there are several logs that are notified.
 */
export const trackSearchPageView =
    (
        events: FormattedEvent[],
        pagination: PaginationData,
        topMatchShown: boolean,
    ) =>
    (_dispatch: any, getState: Function) => {
        const {
            app: { guestId, publicId, sessionId },
            search: { eventSearch },
            location: { slug },
        }: DiscoverState = getState();

        const eventIds = events.map((event) => event.id);

        logDiscoveryRequest({
            eventSearch,
            publicUserId: publicId,
            guestId,
            locationSlug: slug,
            //userGenerated is always unknown at this point in code
            // - did the user change the search or just click a SEO link?
            userGenerated: null,
            navigation: SEARCH_EVENT_NAVIGATION,
            action: GA_PAGE_LOAD_ACTION,
            // variant can be used to track experiments
            resultIds: eventIds,
            numResults: pagination.objectCount,
        });

        const eventData = {
            ...eventSearch,
            sessionId,
            topMatchShown,
        };

        const location = {
            placeId: eventSearch?.places?.[0] || '',
            name: slug || '',
        };

        const transformedSearchAndDiscoveryPage = transformSearchAndDiscovery(
            eventData,
            location,
        );
        updatePageProps(
            {
                eventName: GA_RUN_SEARCH_ACTION,
                page: transformedSearchAndDiscoveryPage,
            },
            'snake',
        );
        updatePageProps(
            {
                eventName: HEAP_SEARCH_PAGE_VIEW,
                page: transformedSearchAndDiscoveryPage,
            },
            'snake',
        );

        logEvent(HEAP_SEARCH_PAGE_VIEW);
    };

export const trackEnterMapView =
    () => (_dispatch: any, getState: () => any) => {
        const state = getState();
        const {
            location: { location },
        } = state;

        trackEventFromState(state, {
            label: location,
            category: GA_SEARCH_CATEGORY,
            action: GA_ENTER_MAP_VIEW,
        });
    };

export const logSearchMetrics =
    () => (dispatch: Function, getState: Function) => {
        const state: DiscoverState = getState();
        const {
            app: { guestId, publicId },
            search: { eventSearch, searchActionToLog },
            location: { slug },
        } = state;

        if (isEmpty(searchActionToLog)) {
            return;
        }

        logDiscoveryRequest({
            eventSearch,
            guestId,
            locationSlug: slug,
            publicUserId: publicId,
            userGenerated: true,
            navigation: SEARCH_EVENT_NAVIGATION,
            action: searchActionToLog,
            // variant can be used to track experiments
            variant: null,
        });

        dispatch({ type: CLEAN_TRACKING_DATA });
    };
