import type {
    SearchResponseEvents,
    SearchResponseEventsData,
} from '@eventbrite/search-utils';
import { ONLINE_BANNER_URL } from '../constants/constants';
import { Branding } from '../types';

export type { SearchResponseEvents, SearchResponseEventsData };

export const transformBrandingResponse = (
    branding: Branding,
    isOnline?: boolean,
) => {
    const {
        attribution: {
            name: artistName,
            summary: artistSummary,
            image: { original: { url: artistImageUrl } = {} as any } = {},
            social_links: artistSocialLinks,
        } = {} as any,
        header: {
            image: {
                original: { url: headerImageUrl } = {} as any,
                url: headerSmallImageUrl,
            } = {} as any,
        } = {},
        middle: {
            image: {
                original: { url: middleImageUrl } = {} as any,
                url: middleSmallImageUrl,
            } = {} as any,
        } = {},
        footer: {
            image: {
                original: { url: footerImageUrl } = {} as any,
                url: footerSmallImageUrl,
            } = {} as any,
        } = {},
        decoration_top_left: {
            image: { original: { url: decorationTopLeftUrl } = {} as any } = {},
        } = {},
        decoration_top_right: {
            image: {
                original: { url: decorationTopRightUrl } = {} as any,
            } = {},
        } = {},
        decoration_bottom_left: {
            image: {
                original: { url: decorationBottomLeftUrl } = {} as any,
            } = {},
        } = {},
        decoration_bottom_right: {
            image: {
                original: { url: decorationBottomRightUrl } = {} as any,
            } = {},
        } = {},
    } = branding;

    let onlineHeaderImage = null;

    if (isOnline) {
        onlineHeaderImage = ONLINE_BANNER_URL;
    }

    return {
        artistName,
        artistSummary,
        artistImageUrl,
        headerImageUrl: onlineHeaderImage || headerImageUrl,
        headerSmallImageUrl: onlineHeaderImage || headerSmallImageUrl,
        middleImageUrl,
        middleSmallImageUrl,
        footerImageUrl,
        footerSmallImageUrl,
        decorationTopLeftUrl,
        decorationTopRightUrl,
        decorationBottomLeftUrl,
        decorationBottomRightUrl,
        artistSocialLinks,
    };
};
