import { combineReducers } from 'redux';

const options = (state = {}) => {
    const nextState = state;

    return nextState;
};

export default combineReducers({
    options,
});
