import { combineReducers } from 'redux';

const isAuthenticated = (state = false) => {
    const nextState = state;

    return nextState;
};

export default combineReducers({
    isAuthenticated,
});
