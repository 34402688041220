import { getTrendingSearches } from '../../api/trendingSearches';
import { transformIntoTrendingSearchesShelfData } from '../utils/trendingSearches';

export const FETCH_TRENDING_SEARCHES_DATA_IN_PROGRESS =
    'FETCH_TRENDING_SEARCHES_DATA_IN_PROGRESS';
export const FETCH_TRENDING_SEARCHES_DATA_SUCCESS =
    'FETCH_TRENDING_SEARCHES_DATA_SUCCESS';

export const trendingSearchesDataInProgress = () => ({
    type: FETCH_TRENDING_SEARCHES_DATA_IN_PROGRESS,
});

export const trendingSearches = (data: any) => ({
    type: FETCH_TRENDING_SEARCHES_DATA_SUCCESS,
    payload: data,
});

export const fetchTrendingSearchesData =
    () => (dispatch: Function, getState: Function) => {
        const {
            location: { slug },
        } = getState();

        dispatch(trendingSearchesDataInProgress());

        return getTrendingSearches(slug)
            .then((response: any) =>
                dispatch(
                    trendingSearches(
                        transformIntoTrendingSearchesShelfData(slug, response),
                    ),
                ),
            )
            .catch(() => {
                // Skip
            });
    };
