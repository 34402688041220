import { getFormattedDateTime, parseDateTime } from '@eventbrite/datetime-fns';
import { gettext } from '@eventbrite/i18n';
import { TrendingIcon as TrendingSvg } from '@eventbrite/marmalade';
import { $FixMe } from '@eventbrite/ts-utils';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import take from 'lodash/take';
import { formatUrl } from 'url-lib';
import {
    EventSuggestion,
    EVENTS_TYPE,
    EVENT_TYPE,
    SuggestionsState,
} from '../../';
import { EVENT_AUTOCOMPLETE_AFFILIATE } from '../../constants/constants';

import React from 'react';
import { getTransformedContent } from '../../utils/transformation';
import { getLocationInfo } from '../../utils/venue';

const _parseEventsSuggestions = (suggestions: EventSuggestion[]) => {
    let eventSuggestions = map(
        suggestions,
        (
            {
                id,
                name,
                startDate,
                startTime,
                timezone,
                primaryVenue,
                url,
                ticketsBy,
                image,
            },
            index: number,
        ) => ({
            ticketsBy,
            value: `${EVENT_TYPE}/${id}`,
            content: name,
            imageUrl: image ? image.url : '',
            imageAlt: name,
            secondaryContent:
                startDate && startTime
                    ? getFormattedDateTime(
                          parseDateTime(startDate, startTime).toISOString(),
                          false,
                          timezone,
                      )
                    : null,
            tertiaryContent: getLocationInfo(
                primaryVenue ? primaryVenue.name : '',
                primaryVenue ? primaryVenue.address.localizedAreaDisplay : '',
            ),
            header: index === 0 ? gettext('Events') : null,
            showDivider: suggestions.length === index + 1 || index === 4,
            dividerVertSpacing: 2,
            url: formatUrl(url, { aff: EVENT_AUTOCOMPLETE_AFFILIATE }),
            isSquareImage: true,
        }),
    );

    eventSuggestions = take(eventSuggestions, 5);

    return eventSuggestions;
};

const _parseQuerySuggestions = (suggestions: $FixMe) => {
    let querySuggestions = map(suggestions, (completion, index: number) => ({
        value: completion,
        content: completion,
        iconType: <TrendingSvg />,
        header: index === 0 ? gettext('Popular') : null,
        showDivider: suggestions.length === index + 1 || index === 4,
        dividerVertSpacing: 2,
    }));

    querySuggestions = take(querySuggestions, 5);

    return querySuggestions;
};

const PARSE_MAP: { [key: string]: any } = {
    [EVENTS_TYPE]: _parseEventsSuggestions,
    query: _parseQuerySuggestions,
    event: _parseEventsSuggestions,
    tags: (tags: string[]) => tags,
    filters: (filters: string[]) => filters,
};

export const parseSuggestions = (response = {}): SuggestionsState => {
    const { result } = getTransformedContent(
        'aKeyThatDoesNotMatterInThisContext',
        {
            response,
        },
    );
    const parsedResponse = reduce(
        result.response,
        (memo, value, key) => {
            if (key in PARSE_MAP) {
                const data = PARSE_MAP[key](value);

                // if we're parsing events, we need the unparsed version as well
                if (key === EVENTS_TYPE) {
                    const rawEvents = reduce(
                        value,
                        (acc, val) => ({
                            ...acc,
                            [val.id]: val,
                        }),
                        {},
                    );

                    return {
                        ...memo,
                        rawEvents,
                        [key]: data,
                    };
                }

                return {
                    ...memo,
                    [key]: data,
                };
            }

            return memo;
        },
        {},
    );

    return {
        ...parsedResponse,
    };
};
