import {
    bboxShape,
    CURRENT_FUTURE,
    EventSearch,
    parseSuggestions,
    SuggestionsState,
} from '@eventbrite/discover-utils';
import { gettext } from '@eventbrite/i18n';
import { setNavigatorLastLocation } from '@eventbrite/personalization';
import {
    getLocationData,
    locationDuck,
    setLocationData,
} from '@eventbrite/redux-destination';
import {
    baseHandleApplyFilter,
    getUpdatedTags,
    handleApplyFilterParams,
    handleRemoveAllFilters as baseHandleRemoveAllFilters,
    handleRemoveFilter as baseHandleRemoveFilter,
    SEARCH_RADIUS_DEFAULTS,
    updateSearchCriteria,
} from '@eventbrite/search-utils';
import {
    deepKeysToCamel,
    keysCamelToSnake,
} from '@eventbrite/transformation-utils';
import { $FixMe } from '@eventbrite/ts-utils';
import omit from 'lodash/omit';
import {
    GA_LOCATION_CHANGE_ACTION,
    GA_REDO_FROM_MAP,
} from '../../constants/analytics';

import { getPlaceFromRequest as getPlaceFromRequestApi } from '@eventbrite/search-utils';
import {
    searchAutocompleteSuggestions as searchAutocompleteSuggestionsApi,
    searchEventSuggestions as searchEventSuggestionsApi,
} from '../api/search';
import { getFilterEventTrackingData } from '../utils/analytics';
import { transformSearchIntoSuggestions } from '../utils/suggestions';
import { fetchThingsToDoShelfData } from './thingsToDoShelf';
import { fetchTrendingSearchesData } from './trendingSearches';
import { showErrorNotification } from './ui';

const {
    actions: { updateLocation },
} = locationDuck;

export const UPDATE_SEARCH_SUGGESTIONS = 'UPDATE_SEARCH_SUGGESTIONS';
export const SUGGESTIONS_LOADING = 'SUGGESTIONS_LOADING';
const updateSearchAutocompleteSuggestions = (
    suggestions?: SuggestionsState,
) => ({
    type: UPDATE_SEARCH_SUGGESTIONS,
    payload: suggestions,
});

export const searchAutocompleteSuggestions =
    (query?: string) => (dispatch: Function, getState?: Function) => {
        if (!query) {
            return dispatch(updateSearchAutocompleteSuggestions({}));
        }

        const {
            location: { placeId, isOnline },
        } = getState?.();

        dispatch({ type: SUGGESTIONS_LOADING });

        return searchAutocompleteSuggestionsApi({
            placeId,
            onlineEventsOnly: isOnline,
            query,
        })
            .then((response: any) =>
                dispatch(
                    updateSearchAutocompleteSuggestions(
                        parseSuggestions(response),
                    ),
                ),
            )
            .catch(() => {
                dispatch(
                    showErrorNotification(
                        gettext(
                            'Error finding events. Please refresh and try again',
                        ),
                    ),
                );
            });
    };

export const searchEventSuggestions =
    (forceOnline = false) =>
    (dispatch: Function, getState: Function) => {
        const {
            location: { placeId, isOnline },
        } = getState();
        dispatch({ type: SUGGESTIONS_LOADING });
        return searchEventSuggestionsApi({
            placeId: forceOnline ? undefined : placeId,
            onlineEventsOnly: forceOnline || isOnline,
        })
            .then((response: any) =>
                dispatch(
                    updateSearchAutocompleteSuggestions(
                        parseSuggestions(
                            transformSearchIntoSuggestions(response),
                        ),
                    ),
                ),
            )
            .catch(() => {
                //do nothing since this happens behind the scenes to
                //pre-populate the event suggestion dropdown.
            });
    };

export const handleRemoveOnlineFilter =
    () => (dispatch: Function, getstate: Function) => {
        const { location } = getstate();

        if (location.fallBack) {
            dispatch(
                searchInformationForPlace({
                    ...location.fallBack,
                }),
            );
        } else {
            // The user arrived to an `online` search or browse page directly.
            // So we make a best guess based on the available information:

            //1. Cookie with location data is stored
            //2. IP lookup to get a best guess
            const locationData = getLocationData();
            if (locationData && locationData.placeId) {
                dispatch(
                    searchInformationForPlace({
                        ...locationData,
                        isOnline: false,
                    }),
                );
            } else {
                getPlaceFromRequestApi()
                    .then(({ place }: { place: string }) => {
                        dispatch(
                            searchInformationForPlace({
                                ...deepKeysToCamel(place),
                                isOnline: false,
                            }),
                        );
                    })
                    .catch(() => {
                        showErrorNotification(
                            gettext(
                                'Unable to find your current location. Please select one',
                            ),
                        );
                    });
            }
        }
    };

interface searchInformationForPlaceProps {
    slug?: string;
    placeId?: string;
    latitude?: number;
    longitude?: number;
    placeType?: string;
    currentPlace?: string;
    currentPlaceParent?: string;
    isUsingCurrentLocation?: boolean;
    isOnline?: boolean;
    userLatLng?: GeolocationCoordinates;
}

export const searchInformationForPlace =
    (
        {
            slug,
            placeId,
            latitude,
            longitude,
            placeType,
            currentPlace,
            currentPlaceParent,
            isUsingCurrentLocation,
            isOnline = false,
            userLatLng,
        }: searchInformationForPlaceProps,
        query?: string,
    ) =>
    (dispatch: Function, getState: Function) => {
        const {
            search: { eventSearch },
            location: oldLocation,
        } = getState();

        const searchActionToLog = GA_LOCATION_CHANGE_ACTION;

        const locationData = {
            slug,
            placeId,
            latitude,
            longitude,
            placeType,
            currentPlace,
            currentPlaceParent,
            isUsingCurrentLocation,
            isOnline,
            userLatLng,
        };

        dispatch({
            type: 'SET_FALLBACK_LOCATION',
            payload: oldLocation,
        });

        dispatch(updateLocation(locationData));

        // See https://docs.google.com/document/d/1Vg-kyxM34gw71vw_5dLcfzFRhzM3scQ3FKtYUlWXLSA/edit?usp=sharing
        placeId && setLocationData(keysCamelToSnake(locationData));

        /* When the user selects "online" from the Recent suggestions the placeId is null and the slug is online,
        but the isOnline is false. Because of that we should change it to reflect the correct filter */
        if (!placeId && slug === 'online') {
            locationData.isOnline = true;
        }

        if (!locationData.isOnline && !!placeId) {
            setNavigatorLastLocation({
                currentPlace,
                currentPlaceParent,
                content: currentPlace,
                secondaryContent: currentPlaceParent,
                value: `recent-${placeId}`,
                latitude,
                longitude,
                placeType,
                placeId,
                slug,
                isOnline,
                isHistorySuggestion: true,
            });
        }

        dispatch(searchEventSuggestions());

        const isPointRadiusActive = !!Object.keys(eventSearch.pointRadius)
            .length;

        const searchQuery = {
            ...eventSearch,
            // Since we are searching by place we can remove the bbox
            bbox: '',
            q: !(!query && query !== '') ? query : eventSearch.q,
            page: 1,
            onlineEventsOnly: isOnline,
            places: isOnline ? [] : [placeId],
            pointRadius: isOnline
                ? {}
                : {
                      ...eventSearch.pointRadius,
                      ...(isPointRadiusActive ? { latitude, longitude } : {}),
                      ...(isUsingCurrentLocation && userLatLng
                          ? {
                                latitude: userLatLng.latitude,
                                longitude: userLatLng.longitude,
                            }
                          : {}),
                      ...(isUsingCurrentLocation &&
                      userLatLng &&
                      !isPointRadiusActive
                          ? {
                                ...SEARCH_RADIUS_DEFAULTS,
                            }
                          : {}),
                  },
        };

        dispatch(updateSearchCriteria(searchQuery, searchActionToLog));
        dispatch(fetchTrendingSearchesData());
        dispatch(fetchThingsToDoShelfData());
    };

export const cleanLongitude = (longitude: number) =>
    (((longitude % 360) + 540) % 360) - 180;

export const searchByBBox =
    (bbox?: bboxShape) => (dispatch: Function, getState: Function) => {
        const state = getState();
        const {
            search: { eventSearch },
        } = state;

        // Create bounding box string of shape: '${left},${bottom},${right},${top}'
        const bboxParam = `${cleanLongitude(bbox?.sw?.lng || 0)},${
            bbox?.sw?.lat
        },${cleanLongitude(bbox?.ne?.lng || 0)},${bbox?.ne?.lat}`;

        const searchQuery: EventSearch = {
            ...eventSearch,
            pointRadius: {},
            onlineEventsOnly: false,
            bbox: bboxParam,
        };

        const searchActionToLog = GA_REDO_FROM_MAP;

        dispatch(
            updateSearchCriteria(
                omit(searchQuery, ['places']),
                searchActionToLog,
            ),
        );
    };

// If a bounding box is available, use it.  If not, search by place
export const searchByBBoxOrPlace =
    (place: $FixMe & { bbox: bboxShape }, query?: string) =>
    (dispatch: Function) => {
        if ('bbox' in place) {
            dispatch(searchByBBox(place.bbox));
        } else {
            dispatch(searchInformationForPlace({ ...place }, query));
        }
    };

export const handleApplyFilter = (props: handleApplyFilterParams) => {
    return baseHandleApplyFilter({
        ...props,
        getTrackingData: getFilterEventTrackingData,
    });
};
/**
 *
 * @param {*} simpleQuery
 *
 * Takes in a preconfigured query. Resets the current search state to
 * null, and applies the passed in query.
 */
export const handleApplyFilterSimple =
    (simpleQuery: {
        subcategories?: string[];
        category?: string;
        format?: string;
        dates?: string;
        remainingQuery: any;
    }) =>
    (dispatch: Function, getState: Function) => {
        const {
            search: { eventSearch },
        } = getState();

        const {
            subcategories = [],
            category = '',
            format = '',
            dates = '',
            ...remainingQuery
        } = simpleQuery;

        const updatedDates = dates ? [CURRENT_FUTURE, dates] : CURRENT_FUTURE;
        const updatedTags = getUpdatedTags([], subcategories, category, format);

        const searchQuery = {
            ...eventSearch,
            q: '',
            price: '',
            dateRange: {},
            currencies: '',
            languages: '',
            page: 1,
            special: [],
            ...{ tags: updatedTags, dates: updatedDates, ...remainingQuery },
        };

        const searchActionToLog = getFilterEventTrackingData({
            ...searchQuery,
        });
        dispatch(updateSearchCriteria(searchQuery, searchActionToLog));
    };

export const handleRemoveFilter = baseHandleRemoveFilter;

export const handleRemoveAllFilters = baseHandleRemoveAllFilters;
