import { combineReducers } from 'redux';
import {
    FETCH_TRENDING_SEARCHES_DATA_IN_PROGRESS,
    FETCH_TRENDING_SEARCHES_DATA_SUCCESS,
} from '../actions/trendingSearches';

const trending = (
    state = [],
    { type, payload }: { type: string; payload?: any },
) => {
    if (type === FETCH_TRENDING_SEARCHES_DATA_IN_PROGRESS) {
        return [];
    }

    if (type === FETCH_TRENDING_SEARCHES_DATA_SUCCESS) {
        return payload?.trending ?? [];
    }

    return state;
};

const viewMoreMarkedNoFollow = (
    state = false,
    { type, payload }: { type: string; payload?: any },
) => {
    if (type === FETCH_TRENDING_SEARCHES_DATA_IN_PROGRESS) {
        return true;
    }

    if (type === FETCH_TRENDING_SEARCHES_DATA_SUCCESS) {
        return payload?.viewMoreMarkedNoFollow ?? true;
    }

    return state;
};

export default combineReducers({
    trending,
    viewMoreMarkedNoFollow,
});
