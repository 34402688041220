import {
    EVENTBRITE_CATEGORY_TO_REFINEMENT,
    EVENTBRITE_FORMAT_TO_REFINEMENT,
    FORMATTED_SUBCATEGORY_MAP,
    isCategory,
    isSubCategory,
} from '@eventbrite/categories';
import type { SearchAndDiscoveryPageProps } from '@eventbrite/datalayer-library';
import { moment } from '@eventbrite/date';
import { EventSearch } from '@eventbrite/discover-utils';
import { FILTER_TYPE_GA_MAP } from '../../constants/analytics';
import { trackEventClickInternal } from '../api/analytics';
/* Internal Tracking */
export const trackInternalAppClick = (trackingData: object) => {
    const defaultData = {
        platform: 'web',
        timestamp: parseInt(moment().format('X'), 10),
        selectionType: 'event',
    };

    trackEventClickInternal({ ...defaultData, ...trackingData });
};

/*
 * We no longer use Google Analytics. This function was originally used to
 * create the necessary ga tracking body from the filter selection.
 * Now we just store filterType for logging to /api/v3/destination/search/log_requests

 * @param selectedFilter -- object -- ${filterType}: ${filterValue}
 *      - Filter object passed to search API upon selection
 */
export const getFilterEventTrackingData = (selectedFilter?: object) => {
    const filterAnalyticsActions: string[] = [];

    for (const [filterType, filterValue] of Object.entries(
        selectedFilter || {},
    )) {
        if (filterValue || filterValue === '') {
            if (filterType in FILTER_TYPE_GA_MAP) {
                const filterAnalyticsAction = FILTER_TYPE_GA_MAP[filterType];
                filterAnalyticsActions.push(filterAnalyticsAction);
            }
        }
    }

    return filterAnalyticsActions.join(',');
};

function joinOrDefault(array: string[], defaultValue: string): string {
    return array.length ? array.join(', ') : defaultValue;
}

function transformValue(
    key: string,
    value: any,
): string | number | boolean | {} {
    let transformedValue = value;
    switch (key) {
        case 'dateRange': {
            transformedValue = value.to ? value : 'any';
            break;
        }
        case 'dates': {
            if (Array.isArray(value) && value.length === 1)
                transformedValue = value[0];
            else if (Array.isArray(value) && value.length > 1)
                transformedValue = value
                    .filter((value) => value !== 'current_future')
                    .join(',');
            else transformedValue = 'any';
            break;
        }
        case 'price': {
            transformedValue = value ? value : 'any';
            break;
        }
        case 'tags': {
            transformedValue = joinOrDefault(value, '');
            break;
        }
        case 'currencies': {
            transformedValue = joinOrDefault(value, 'any');
            break;
        }
        case 'languages': {
            transformedValue = joinOrDefault(value, 'any');
            break;
        }
        case 'special': {
            transformedValue = value.length ? true : false;
            break;
        }
        case 'tagsCategoryId': {
            transformedValue = value.length
                ? EVENTBRITE_CATEGORY_TO_REFINEMENT[value[0]]
                : 'any';
            break;
        }
        case 'tagsSubCategoryId': {
            transformedValue = value.length
                ? FORMATTED_SUBCATEGORY_MAP[
                      value[0] as keyof typeof FORMATTED_SUBCATEGORY_MAP
                  ].refinement
                : 'any';
            break;
        }
        case 'tagsFormatId': {
            transformedValue = value.length
                ? EVENTBRITE_FORMAT_TO_REFINEMENT[value[0]]
                : 'any';
            break;
        }
        // Start Default Fallthrough Cases that do not require transformation.
        // Listed for completeness to reflect consideration for the property.
        case 'topMatchShown':
        case 'dedup':
        case 'adSlots':
        case 'onlineEventsOnly':
        case 'page':
        case 'pageSize':
        case 'places':
        case 'q':
        default: {
            transformedValue = value;
            break;
        }
    }

    return transformedValue;
}

/**
 * This Lookup map transforms known keys to values we want to preferred names in heap.
 * These names have been chosen to reduce ambiguity and likelyhood of collision with event props.
 * These names end up as column names in snowflake for events.
 */
const searchKeyTransformations: Record<string, string> = {
    currencies: 'search_filter_currencies',
    dateRange: 'search_filter_date_range',
    dates: 'search_filter_dates_relative_to',
    dedup: 'deDupedResultsShown',
    includePromotedEventsFor: 'search_filter_include_promoted_events',
    languages: 'search_filter_langugages',
    onlineEventsOnly: 'search_filter_online_events_only',
    page: 'search_filter_page_number',
    pageSize: 'search_filter_page_size',
    price: 'search_filter_price',
    q: 'search_bar_keywords',
    special: 'search_filter_following_organizers',
    tagsCategoryId: 'search_filter_category_id',
    tagsSubCategoryId: 'search_filter_subcategory_id',
    tagsFormatId: 'search_filter_format_id',
    tags: 'search_filter_tags',
    places: 'search_filter_location',
};

function tagHasKey(key: string) {
    return (candidate: string) => candidate.indexOf(key) > -1;
}

function reshapeFilterTags(
    tags: string[],
    location: { name: string; placeId: string },
): [string, any][] {
    const hasFormat = tagHasKey('EventbriteFormat');

    const tagsCategoryId = tags.filter(isCategory);
    const tagsSubCategoryId = tags.filter(isSubCategory);
    const tagsFormatId = tags.filter(hasFormat);
    const otherTags = tags.filter(
        (x: string) => !isCategory(x) && !isSubCategory(x) && !hasFormat(x),
    );

    return [
        ['tags', otherTags],
        ['tagsCategoryId', tagsCategoryId],
        ['tagsSubCategoryId', tagsSubCategoryId],
        ['tagsFormatId', tagsFormatId],
        ['places', location],
    ];
}

export function transformSearchAndDiscovery(
    input: EventSearch,
    location: { name: string; placeId: string },
): SearchAndDiscoveryPageProps {
    const entries: [string, any][] = [
        ...Object.entries(input).filter(
            // These keys are overriden below.
            ([key, _]) => key != 'tags' && key != 'places',
        ),
        ...reshapeFilterTags(input.tags || [], location),
    ];

    const transformed: [string, any][] = entries.map(
        ([key, value]: [string, any]): [string, any] => {
            const newKey = searchKeyTransformations[key] || key;
            return [newKey, transformValue(key, value)];
        },
    );

    return Object.fromEntries(transformed) as SearchAndDiscoveryPageProps;
}
