import { EventSearch } from '@eventbrite/discover-utils';
import { sdkRequest } from '@eventbrite/http';
import { keysCamelToSnake } from '@eventbrite/transformation-utils';
import { $FixMe } from '@eventbrite/ts-utils';
import { getDiscoveryRequestPayload } from '../../pages/search/utils/analytics';

const internalClickTrackingUrl = '/api/v3/destination/search/log_clicks/';
const internalRequestLoggingUrl = '/api/v3/destination/search/log_requests/';

export const trackEventClickInternal = (data: any) => {
    const internalLoggingPromise = sdkRequest(internalClickTrackingUrl, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
            clicks: [keysCamelToSnake(data)],
        }),
    });

    if (internalLoggingPromise) {
        internalLoggingPromise.catch((err: Error) => {
            // Swallow errors when in prod, too much noise and doesn't affect UX
            if (process.env.NODE_ENV !== 'production') {
                throw err;
            }
        });
    }
};

/*
Takes user info, search context (filters, location, etc), and result information and logs it.

Returns searchId which can be used to log to GA.

If null is returned for the searchId, then something is wrong and the searchId shouldn't be logged to GA.
 */
interface logDiscoveryRequestProps {
    eventSearch?: EventSearch;
    publicUserId?: string;
    guestId?: string;
    locationSlug?: string;
    userGenerated?: boolean | null;
    navigation?: string;
    action?: string;
    blobField?: object;
    variant?: string | null;
    resultIds?: $FixMe;
    numResults?: number;
}

export const logDiscoveryRequest = ({
    eventSearch,
    publicUserId,
    guestId,
    locationSlug,
    userGenerated,
    navigation,
    action,
    blobField,
    variant,
    resultIds,
    numResults,
}: logDiscoveryRequestProps) => {
    const discoveryRequestPayload = getDiscoveryRequestPayload({
        eventSearch,
        publicUserId,
        guestId,
        locationSlug,
        userGenerated,
        navigation,
        action,
        blobField,
        variant,
        resultIds,
        numResults,
    });

    if (!discoveryRequestPayload) {
        return null;
    }

    const internalLoggingPromise = sdkRequest(internalRequestLoggingUrl, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
            requests: [keysCamelToSnake(discoveryRequestPayload)],
        }),
    });

    if (internalLoggingPromise) {
        internalLoggingPromise.catch(() => {
            //swallow error as it has no impact on user
        });
    }

    return discoveryRequestPayload.searchId;
};
