import { UPDATE_CONTENT, type EventSearch } from '@eventbrite/discover-utils';

// Action types
export const CLEAN_TRACKING_DATA = 'CLEAN_TRACKING_DATA';
export const UPDATE_SEARCH_CRITERIA = 'UPDATE_SEARCH_CRITERIA';

// Actions
export const updateSearchCriteria = (
    searchQuery: Omit<EventSearch, 'bbox'>,
    searchActionToLog = '',
) => ({
    type: UPDATE_SEARCH_CRITERIA,
    payload: {
        eventSearch: searchQuery,
        searchActionToLog,
    },
});

// Reducers
const q = (state = '', { type, payload }: { type: string; payload?: any }) => {
    if (
        type === UPDATE_CONTENT &&
        payload &&
        payload.searchCriterias.eventSearch.q
    ) {
        return payload.searchCriterias.eventSearch.q;
    }

    if (type === UPDATE_SEARCH_CRITERIA) {
        return payload.eventSearch.q || '';
    }

    return state;
};

const dateRange = (
    state = {},
    { type, payload }: { type: string; payload?: any },
) => {
    if (
        type === UPDATE_CONTENT &&
        payload &&
        payload.searchCriterias.eventSearch.dateRange
    ) {
        return payload.searchCriterias.eventSearch.dateRange;
    }

    if (type === UPDATE_SEARCH_CRITERIA) {
        return payload.eventSearch.dateRange || {};
    }

    return state;
};

const durationRange = (
    state = {},
    { type, payload }: { type: string; payload?: any },
) => {
    if (
        type === UPDATE_CONTENT &&
        payload &&
        payload.searchCriterias.eventSearch.durationRange
    ) {
        return payload.searchCriterias.eventSearch.durationRange;
    }

    if (type === UPDATE_SEARCH_CRITERIA && payload.eventSearch.durationRange) {
        return payload.eventSearch.durationRange;
    }

    return state;
};

const dates = (
    state = '',
    { type, payload }: { type: string; payload?: any },
) => {
    if (type === UPDATE_CONTENT && payload) {
        return payload.searchCriterias.eventSearch.dates || '';
    }

    if (type === UPDATE_SEARCH_CRITERIA) {
        return payload.eventSearch.dates || '';
    }

    return state;
};

const organizationsOr = (
    state = [],
    { type, payload }: { type: string; payload?: any },
) => {
    if (type === UPDATE_CONTENT && payload) {
        return payload.searchCriterias.eventSearch.organizationsOr || [];
    }
    // optimistic update
    if (type === UPDATE_SEARCH_CRITERIA) {
        return payload.eventSearch.organizationsOr || [];
    }

    return state;
};

const hash = (
    state = '',
    { type, payload }: { type: string; payload?: any },
) => {
    if (type === UPDATE_CONTENT && payload) {
        return payload.searchCriterias.eventSearch.hash || '';
    }
    // optimistic update
    if (type === UPDATE_SEARCH_CRITERIA) {
        return payload.eventSearch.hash || '';
    }

    return state;
};

const dedup = (state = false) => state;

const places = (
    state = [],
    { type, payload }: { type: string; payload?: any },
) => {
    if (
        type === UPDATE_CONTENT &&
        payload &&
        payload.searchCriterias.eventSearch.places
    ) {
        return payload.searchCriterias.eventSearch.places;
    }

    if (type === UPDATE_SEARCH_CRITERIA && payload.eventSearch.places) {
        return payload.eventSearch.places;
    }

    return state;
};

const price = (
    state = '',
    { type, payload }: { type: string; payload?: any },
) => {
    if (type === UPDATE_CONTENT && payload) {
        return payload.searchCriterias.eventSearch.price || '';
    }

    if (type === UPDATE_SEARCH_CRITERIA) {
        return payload.eventSearch.price || '';
    }

    return state;
};

const tags = (
    state = [],
    { type, payload }: { type: string; payload?: any },
) => {
    if (type === UPDATE_CONTENT && payload) {
        return payload.searchCriterias.eventSearch.tags || [];
    }

    if (type === UPDATE_SEARCH_CRITERIA) {
        return payload.eventSearch.tags || [];
    }

    return state;
};

const pointRadius = (
    state = {},
    { type, payload }: { type: string; payload?: any },
) => {
    if (type === UPDATE_CONTENT && payload) {
        return payload.searchCriterias.eventSearch.pointRadius || {};
    }

    if (type === UPDATE_SEARCH_CRITERIA) {
        return payload.eventSearch.pointRadius || {};
    }

    return state;
};

const currencies = (
    state = [],
    { type, payload }: { type: string; payload?: any },
) => {
    if (type === UPDATE_CONTENT && payload) {
        return payload.searchCriterias.eventSearch.currencies || [];
    }

    if (type === UPDATE_SEARCH_CRITERIA) {
        return payload.eventSearch.currencies || [];
    }

    return state;
};

const experiences = (
    state = [],
    { type, payload }: { type: string; payload?: any },
) => {
    if (type === UPDATE_CONTENT && payload) {
        return payload.searchCriterias.eventSearch.experiences || [];
    }

    if (type === UPDATE_SEARCH_CRITERIA) {
        return payload.eventSearch.experiences || [];
    }
    return state;
};

const languages = (
    state: string[] = [],
    { type, payload }: { type: string; payload?: any },
) => {
    if (type === UPDATE_CONTENT && payload) {
        return payload.searchCriterias.eventSearch.languages || [];
    }

    if (type === UPDATE_SEARCH_CRITERIA) {
        return payload.eventSearch.languages || [];
    }

    return state;
};

const special = (
    state = [],
    { type, payload }: { type: string; payload?: any },
) => {
    let nextState = state;

    if (type === UPDATE_CONTENT && payload) {
        if (payload.searchCriterias.eventSearch.special) {
            nextState = payload.searchCriterias.eventSearch.special;
        } else {
            nextState = [];
        }
    }

    if (type === UPDATE_SEARCH_CRITERIA) {
        if (payload.eventSearch.special) {
            nextState = payload.eventSearch.special;
        } else {
            nextState = [];
        }
    }

    return nextState;
};

const searchId = (
    state = '',
    { type, payload }: { type: string; payload?: any },
) => {
    if (
        type === UPDATE_CONTENT &&
        payload &&
        payload.searchCriterias.searchId
    ) {
        return payload.searchCriterias.searchId;
    }

    return state;
};

const page = (
    state = 1,
    { type, payload }: { type: string; payload?: any },
) => {
    if (
        type === UPDATE_CONTENT &&
        payload &&
        payload.searchCriterias.eventSearch.page
    ) {
        return payload.searchCriterias.eventSearch.page;
    }

    if (type === UPDATE_SEARCH_CRITERIA && payload.eventSearch.page) {
        return payload.eventSearch.page;
    }

    return state;
};

const bbox = (
    state = '',
    { type, payload }: { type: string; payload?: any },
) => {
    if (
        type === UPDATE_CONTENT &&
        payload &&
        payload.searchCriterias.eventSearch.bbox
    ) {
        return payload.searchCriterias.eventSearch.bbox;
    }

    if (type === UPDATE_SEARCH_CRITERIA && payload.eventSearch.bbox) {
        return payload.eventSearch.bbox;
    }

    if (
        type === UPDATE_SEARCH_CRITERIA &&
        state !== '' &&
        !payload.eventSearch.bbox
    ) {
        // Reset bbox search param
        return '';
    }

    return state;
};

/* Since we are using a custom page size, our reducer is
hydrated with the key as a value so need to declare. */
const pageSize = (state = '') => {
    const nextState = state;

    return nextState;
};

/* Temporary solution to allow for search results with
ticket fly only events returned. If reducer hydrated with
a value here, it should be maintained for the entire session
so no need to further update the state. */
const source = (state = []) => {
    const nextState = state;

    return nextState;
};

const aggs = (state = {}) => {
    const nextState = state;

    return nextState;
};

const onlineEventsOnly = (
    state = false,
    { type, payload }: { type: string; payload?: any },
) => {
    if (
        type === UPDATE_SEARCH_CRITERIA &&
        'onlineEventsOnly' in payload.eventSearch
    ) {
        return payload.eventSearch.onlineEventsOnly;
    }

    return state;
};

const clientTimezone = (state = '') => {
    const nextState = state;

    return nextState;
};

const includePromotedEventsFor = (state = null) => {
    const nextState = state;
    return nextState;
};

const searchActionToLog = (
    state = '',
    { type, payload }: { type: string; payload?: any },
) => {
    if (type === UPDATE_SEARCH_CRITERIA) {
        return payload.searchActionToLog || '';
    }

    if (type === CLEAN_TRACKING_DATA) {
        return '';
    }

    return state;
};

/**
 * Reducer object for search criteria
 * Requires calling combineReducer
 */
export const eventSearch = {
    q,
    dates,
    dateRange,
    durationRange,
    organizationsOr,
    dedup,
    hash,
    places,
    price,
    tags,
    page,
    pageSize,
    bbox,
    source,
    aggs,
    onlineEventsOnly,
    languages,
    currencies,
    experiences,
    clientTimezone,
    special,
    pointRadius,
    includePromotedEventsFor,
};

/**
 * Reducer object for search tracking data
 */
export default {
    eventSearch,
    searchActionToLog,
    searchId,
};
