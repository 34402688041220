import { combineReducers } from 'redux';
import {
    SET_EXPERIMENT_OVERRIDE,
    SET_SHOULD_SHOW_DEBUG_OUTPUT,
} from '../actions/admin';

const experimentOverride = (
    state = { experimentName: 'none', variant: 'A' },
    { type, payload }: { type: string; payload?: any },
) => {
    let nextState = state;

    if (type === SET_EXPERIMENT_OVERRIDE) {
        nextState = payload;
    }

    return nextState;
};

const shouldShowDebugOutput = (
    state = true,
    { type, payload }: { type: string; payload?: any },
) => {
    let nextState = state;

    if (type === SET_SHOULD_SHOW_DEBUG_OUTPUT) {
        nextState = payload;
    }

    return nextState;
};

export default combineReducers({
    experimentOverride,
    shouldShowDebugOutput,
});
