import { transformUtil } from '@eventbrite/transformation-utils';
import { arrayOf, Schema } from 'normalizr';

export const getTransformedContent = (key: any, response: any) => {
    /* Due to how the normalizr library is handling this instantiation
   server side, necessary to do an additional interpolation step. */
    const content = new Schema(`${key}`);

    return transformUtil({
        response,
        schema: {
            [key]: arrayOf(content),
        },
    });
};
