import {
    DEFAULT_DESTINATION_EXPANSIONS,
    DestinationEvent,
} from '@eventbrite/event-renderer';
import { sdkRequest } from '@eventbrite/http';

export type EventExpansionsApiResponse = { events: DestinationEvent[] };

export const DEFAULT_EXPANSIONS = [
    'event_sales_status',
    'image',
    'primary_venue',
    'saves',
    'ticket_availability',
    'primary_organizer',
    'public_collections',
];

const DESTINATION_EVENTS_ENDPOINT = '/api/v3/destination/events/';
const BFF_EVENTS_ENDPOINT = '/d/api/events/';

export const fetchEventExpansions = (
    eventIds: string[] = [],
    expansions = DEFAULT_DESTINATION_EXPANSIONS,
    useBff = false,
) => {
    const eventIdsJoined = eventIds.join(',');
    const params = `event_ids=${eventIdsJoined}&page_size=${eventIds.length}&expand=${expansions}`;

    if (useBff) {
        return fetchEventsBff(`${BFF_EVENTS_ENDPOINT}?${params}`);
    }

    return sdkRequest(`${DESTINATION_EVENTS_ENDPOINT}?${params}`);
};

export const fetchSearchEventExpansions = (
    eventIds: string[],
    useBff = false,
): Promise<EventExpansionsApiResponse> => {
    return fetchEventExpansions(eventIds, DEFAULT_EXPANSIONS, useBff);
};

async function fetchEventsBff(
    url: string,
): Promise<EventExpansionsApiResponse> {
    return fetch(url, {
        method: 'GET',
    }).then((response) => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json() as Promise<EventExpansionsApiResponse>;
    });
}
