import { LocationState } from '@eventbrite/discover-utils';
import { setWindowLocation } from '@eventbrite/http';
import { locationDuck } from '@eventbrite/redux-destination';
import {
    constructNormalizedSearchUrl,
    constructSearchUrl,
    getCurrentNormalizedUrl,
    updateSearchCriteria,
} from '@eventbrite/search-utils';
import { RouteComponentProps } from 'react-router-dom';
import {
    DiscoverState,
    SearchState,
    ThingsToDoShelfState,
    TrendingSearchesResultState,
} from '../../types';
import {
    getSearchEventHeaderTitle,
    updateDocumentTitle,
} from '../../utils/general';
import { thingsToDoShelf } from './thingsToDoShelf';
import { trendingSearches } from './trendingSearches';

const {
    actions: { updateLocation },
} = locationDuck;

export const STORE_EVENT_SEARCH_AND_LOCATION_BY_URL =
    'STORE_EVENT_SEARCH_AND_LOCATION_BY_URL';

/*
 * In order to restore previous eventSearch data only from url we need to store them in redux mapped by the url
 */
export const storeEventSearchAndLocationByUrl = (
    url: string,
    search: SearchState,
    location: LocationState,
    thingsToDoShelf: ThingsToDoShelfState,
    trendingSearchesResult: TrendingSearchesResultState,
) => ({
    type: STORE_EVENT_SEARCH_AND_LOCATION_BY_URL,
    payload: {
        url,
        search,
        location,
        thingsToDoShelf,
        trendingSearchesResult,
    },
});

/*
 * This function ensures the redux event search and the url are sync when the url changes
 * We are unable right now to generate a event search object from the url, so we need to sync both sources of truth.
 */
export const restoreEventSearchFromUrl =
    (history: RouteComponentProps['history']) =>
    (dispatch: Function, getState: Function) => {
        const { search, location, previousUrlContext, app }: DiscoverState =
            getState();

        // Fetch the current url
        const currentUrl = getCurrentNormalizedUrl(history);

        // Compute the url of the event search data
        const eventSearchUrl = constructNormalizedSearchUrl(
            search.eventSearch.page,
            search.eventSearch,
            location.slug,
        );

        // Url and event search are synced, nothing to do here
        if (currentUrl === eventSearchUrl) {
            return;
        }

        // Is the user returning to a previous page?
        const previousEventSearch = previousUrlContext[currentUrl];

        if (!previousEventSearch) {
            // This occurs when  the user has force a reload and then navigated to a previous page
            // Since we cannot infere the event search information from the URL we need to do a hard reload
            // This reload is seamless in production due to
            setWindowLocation(currentUrl);
        } else {
            // User is returning to a previous page
            dispatch(updateLocation(previousEventSearch.location));
            dispatch(
                updateSearchCriteria(
                    previousEventSearch.search.eventSearch,
                    previousEventSearch.search.searchActionToLog,
                ),
            );
            if (app.featureFlags && app.featureFlags.enableThingsToDoPhase2) {
                dispatch(thingsToDoShelf(previousEventSearch.thingsToDoShelf));
            }
            dispatch(
                trendingSearches(previousEventSearch.trendingSearchesResult),
            );
        }
    };

/*
 * This function updates the url with the new event search and stores location / search in case user navigates back and forth
 * If the url is already updated we should do nothing
 * We are unable right now to generate a event search object from the url, so we need to sync both sources of truth.
 */
export const syncEventSearchAndUrl =
    (history: RouteComponentProps['history']) =>
    (dispatch: Function, getState: Function) => {
        const {
            search,
            location,
            thingsToDoShelf,
            trendingSearchesResult,
            app,
        }: DiscoverState = getState();
        const locale = app?.locale || '';
        const usesLanguageSubdirectory =
            app?.uses_language_subdirectory || false;

        // Fetch the current url
        const currentUrl = getCurrentNormalizedUrl(history);

        // Compute the url of the event search data
        const eventSearchUrl = constructNormalizedSearchUrl(
            search.eventSearch.page,
            search.eventSearch,
            location.slug,
            usesLanguageSubdirectory,
            locale,
        );

        // Store eventSearch data
        dispatch(
            storeEventSearchAndLocationByUrl(
                eventSearchUrl,
                search,
                location,
                thingsToDoShelf,
                trendingSearchesResult,
            ),
        );

        // Url and event search are synced, nothing to do here
        if (currentUrl === eventSearchUrl) {
            return;
        }

        // Navigate to the new url
        const nextUrl = constructSearchUrl(
            search.eventSearch.page,
            search.eventSearch,
            location.slug,
            history.location.search,
            usesLanguageSubdirectory,
            locale,
        );
        history.push(nextUrl);
        updateSearchDocumentTitle(location, search);
    };

function updateSearchDocumentTitle(
    { currentPlace, currentPlaceParent, placeType, isOnline }: LocationState,
    { eventSearch }: SearchState,
) {
    // Update page title with new search query data
    updateDocumentTitle(
        getSearchEventHeaderTitle(
            { currentPlace, currentPlaceParent, placeType, isOnline },
            { eventSearch },
            true,
        ),
    );
}
