import React from 'react';

interface BreadCrumbListItemSeparatorProps {
    renderSeparator: boolean;
}

/**
 * If renderSeparator is `false` will
 * return null.
 *
 * Otherwise will render its child wrapped in a span
 * defining separator specific props.
 *
 * @param props
 * @returns ReactElement || null
 */
export const BreadCrumbListItemSeparator = (
    props: React.PropsWithChildren<BreadCrumbListItemSeparatorProps>,
) => {
    if (!props.renderSeparator) {
        return null;
    }

    return (
        <span data-testid="breadcrumb-separator" aria-hidden="true">
            {props.children}
        </span>
    );
};
