import { HAS_WINDOW } from '@eventbrite/feature-detection';
import { logEvent } from '@eventbrite/statsig';

export const SET_FILTER_TYPE_TO_SHOW = 'search/filters/setFilterTypeToShow';
export const setFilterTypeToShow = (val: string[]) => ({
    type: SET_FILTER_TYPE_TO_SHOW,
    payload: val,
});

export const OPEN_CLOSE_ANIMATED_DRAWER =
    'search/filters/openCloseAnimatedDrawer';
export const openCloseAnimatedDrawer =
    (open: boolean, filterType?: string, shouldScroll = true) =>
    (dispatch: Function) => {
        if (open) {
            logEvent('Search - Filters - Click - Show Mobile Filter Panel');
        }

        dispatch({
            type: OPEN_CLOSE_ANIMATED_DRAWER,
            payload: { open, filterType },
        });

        if (shouldScroll && HAS_WINDOW) {
            window.scrollTo(0, 0);
        }
    };
