const _getTrendingSearchPath = (locationSlug: string, searchTerm: string) => {
    return `/d/${locationSlug}/${searchTerm}/`;
};

export const transformIntoTrendingSearchesShelfData = (
    locationSlug: string,
    data: any,
) => {
    const {
        trending: { trending_searches },
    } = data;

    if (!trending_searches || !trending_searches.length) {
        return {
            trending: [],
            viewMoreMarkedNoFollow: true,
        };
    }

    const trendingSearches = trending_searches.slice(0, 12);

    trendingSearches.forEach((trendingSearch: any, index: number) => {
        trendingSearch.id = index;
        trendingSearch.search_path = _getTrendingSearchPath(
            locationSlug,
            trendingSearch.formatted,
        );
    });

    return {
        trending: trendingSearches,
        viewMoreMarkedNoFollow: true,
    };
};
