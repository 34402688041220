import { eventsDuck } from '@eventbrite/redux-destination';

const {
    api: {
        saveEvent: saveEventApi,
        unsaveEvent: unsaveEventApi,
        loggedOutSaveRedirect,
    },
} = eventsDuck;

export const UPDATE_EVENT_SAVED_BY_YOU = 'updateEventSavedByYou';
export const updateEventSavedByYou = (
    eventId: number | string,
    savedByYou: boolean,
) => ({
    type: UPDATE_EVENT_SAVED_BY_YOU,
    payload: {
        eventId,
        savedByYou,
    },
});

const _loggedInSaveAction = (
    dispatch: Function,
    eventId: number,
    shouldSave: boolean,
) => {
    if (shouldSave) {
        //optimistic state set
        dispatch(updateEventSavedByYou(eventId, true));

        return saveEventApi(eventId)
            .then(() => {
                //Resolved promise so view knows if the event save was successful
                return Promise.resolve('success');
            })
            .catch(() => dispatch(updateEventSavedByYou(eventId, false)));
    }
    //optimistic state set
    dispatch(updateEventSavedByYou(eventId, false));

    return unsaveEventApi(eventId)
        .then(() => {
            //Resolved promise so view knows that the event unsave was successful
            return Promise.resolve('success');
        })
        .catch(() => dispatch(updateEventSavedByYou(eventId, true)));
};

export const saveEvent =
    (eventId: number, shouldSave?: boolean, location?: object) =>
    (dispatch: Function, getState: () => any) => {
        const state = getState();
        const {
            auth: { isAuthenticated },
        } = state;

        if (isAuthenticated) {
            return _loggedInSaveAction(dispatch, eventId, shouldSave || false);
        }

        return loggedOutSaveRedirect(eventId, location);
    };
