import { EventSearch } from '@eventbrite/discover-utils';
import isArray from 'lodash/isArray';

/**
 * @param  {string}     s: string to be hashed
 * @return {string}     string hash of the string passed
 */
const hash = (s: string) =>
    s.split('').reduce((hashSum, char) => {
        const chrHash = (hashSum << 5) - hashSum + char.charCodeAt(0);

        return chrHash & chrHash;
    }, 0);

const _hashToPositiveIntString = (str: string) =>
    (hash(str) >>> 0).toString(16);

export const getSearchId = ({
    eventSearch,
    guestId,
}: {
    eventSearch: EventSearch;
    guestId?: string;
}) => {
    if (!guestId) {
        return null;
    }
    const guestHash = _hashToPositiveIntString(guestId);

    let {
        q = '',
        dates = [],
        dateRange = {},
        tags = [],
        bbox = '',
        places: placeIds = [],
        page = 1,
        source = [],
        price = '',
        currencies = [],
        languages = [],
    } = eventSearch;

    const { to: endDate = '', from: startDate = '' } = dateRange;

    if (typeof dates === 'string' || dates instanceof String) {
        dates = [dates];
    }

    const normalizeStringToArray = (val: any) => (val === '' ? [] : val);

    const canonicalArray = [
        q,
        dates.sort(),
        startDate,
        endDate,
        normalizeStringToArray(tags).sort(),
        bbox,
        placeIds.sort(),
        page,
        source.sort(),
        price,
        normalizeStringToArray(currencies).sort(),
        normalizeStringToArray(languages).sort(),
        //be careful if you want to add the location slug here - when location changes the slug is determined after the
        //request
    ];

    const queryHash = _hashToPositiveIntString(JSON.stringify(canonicalArray));

    const searchId = `srch1-${guestHash}-${queryHash}`;

    return searchId;
};

const _getLatLngRadiusKmFromBBox = (bbox: any) => {
    if (!bbox) {
        return [null, null, null];
    }

    const EARTH_CIRCUMFERENCE_KM = 40030;
    const [westLng, southLat, eastLng, northLat] = bbox
        .split(',')
        .map((x: string) => parseFloat(x));
    const lat = (southLat + northLat) / 2;
    const lng = (eastLng + westLng) / 2;
    const diffLat = northLat - lat;
    const radiusKm = (diffLat * EARTH_CIRCUMFERENCE_KM) / 360;

    return [lat, lng, radiusKm];
};

/*
Gather search context and search results data. See ebanalytics definitions
https://github.com/eventbrite/ebanalytics/blob/1085dd4/ebanalytics/containers/search/discovery_request_v2_action.py

TODO: evenutally we want this to service city_browse and feed pages - in those situations we don't have an eventSearch
but we do have a placeId - add placeId to this request and also send placeId and navigation to getSearchId so that
each bucket gets a unique navigation.
 */

interface DiscoveryRequestPayloadProps {
    searchId?: string;
    eventSearch?: EventSearch;
    publicUserId?: any;
    guestId?: string;
    locationSlug?: any;
    userGenerated?: any;
    navigation?: any;
    action?: any;
    blobField?: any;
    variant?: any;
    resultIds?: any;
    numResults?: any;
}

/* eslint-disable complexity */
export const getDiscoveryRequestPayload = ({
    searchId,
    eventSearch,
    publicUserId = null,
    guestId = '',
    locationSlug = null,
    userGenerated = null,
    navigation = null,
    action = null,
    blobField = null,
    variant = null,
    resultIds = null,
    numResults = null,
}: DiscoveryRequestPayloadProps) => {
    if (!eventSearch) {
        return null;
    }
    if (!guestId && !searchId) {
        // we can't make a meaningful searchId
        return null;
    }

    let _searchId;

    if (!searchId) {
        _searchId = getSearchId({ eventSearch, guestId });
    }

    let dateFilterKeyword = eventSearch.dates || null;

    if (isArray(dateFilterKeyword)) {
        if (dateFilterKeyword.length > 1) {
            // While only one dateFilterKeyword should be selected `current_future` is often included even though the
            // other keywords already imply `current_future`. As a special case, we remove this keyword.
            dateFilterKeyword = dateFilterKeyword.filter(
                (keyword) => keyword !== 'current_future',
            );
            dateFilterKeyword.sort();
        }
        dateFilterKeyword = dateFilterKeyword.join(',');
    }

    let placeId = eventSearch.places || '';

    if (placeId && isArray(placeId)) {
        // almost definitely placeId is an array of size 1
        placeId.sort();
        placeId = placeId.join(',');
    }

    let tags = null;

    if (eventSearch.tags) {
        tags = (eventSearch.tags || []).sort();
    }

    const [lat, lng, radiusKm] = _getLatLngRadiusKmFromBBox(eventSearch.bbox);
    const dateFilterStart = eventSearch.dateRange
        ? eventSearch.dateRange.from || null
        : null;
    const dateFilterEnd = eventSearch.dateRange
        ? eventSearch.dateRange.to || null
        : null;

    const queryText = eventSearch.q ? eventSearch.q : null;

    const payload = {
        // general info
        searchId: searchId || _searchId,
        timestamp: new Date().getTime(),
        userId: parseInt(publicUserId, 10) || null,
        userGenerated,
        queryText,
        dateFilterKeyword,
        dateFilterStart,
        dateFilterEnd,
        priceFilter: eventSearch.price || null,
        placeId,
        placeSlug: locationSlug,
        lat,
        lng,
        radiusKm,
        tags,
        platform: 'web',
        navigation,
        action,
        blobField: blobField ? JSON.stringify(blobField) : null,

        // response info
        variant,
        resultIds,
        numResults,
    };

    return payload;
};
/* eslint-enable complexity */
