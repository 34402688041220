import compact from 'lodash/compact';
import filter from 'lodash/filter';
import uniq from 'lodash/uniq';

import { isCategory, isSubCategory } from '@eventbrite/categories';
import { FORMAT_TAG_TYPE } from '@eventbrite/discover-utils';

export const isTagType = (tag: string, type: string) => tag.indexOf(type) > -1;

export const getUpdatedTags = (
    currentTags: string[],
    subcategories?: string | string[],
    category?: string,
    format?: string,
) => {
    //Return current tags if no tag or format passed
    if (
        subcategories === undefined &&
        category === undefined &&
        format === undefined
    ) {
        return currentTags;
    }

    let updatedTags = [...currentTags];
    let filteredTags: string[] = [];

    if ((category && isCategory(category)) || category === '') {
        //updated tags should equal the new array minus the previously selected category and subcategories
        filteredTags = updatedTags
            .filter((val) => !isCategory(val))
            .filter((val) => category === '' || !isSubCategory(val));
        updatedTags = [...filteredTags, category];
    }

    if (format || format === '') {
        updatedTags = [
            ...filter(updatedTags, (val) => !isTagType(val, FORMAT_TAG_TYPE)),
            format,
        ];
    }

    if (subcategories) {
        const filteredSubcategories = (
            Array.isArray(subcategories) ? subcategories : [subcategories]
        ).filter(isSubCategory);
        // if there are any subcategories, remove any existing category and subcategories.
        filteredTags = updatedTags
            .filter((val) => !isSubCategory(val))
            .filter((val) => subcategories.length && !isCategory(val));
        updatedTags = uniq([...filteredTags, ...filteredSubcategories]).sort();
    }

    return compact(updatedTags);
};

/**
 *
 * @param currentTags The current list of tags.
 * @param filters An object indicating which tags to remove
 * @returns A filtered version of the current tags with any tags matching the filters removed
 */
export const getUpdatedTagsForRemoval = (
    currentTags: string[],
    { subcategories = false, category = false, format = false },
) => {
    // This approach assues there will only ever be at most one of each type
    // If that is to change than the logic here will need to be updated
    return compact(
        currentTags.map((currentTag: string) => {
            if (
                (category && isCategory(currentTag)) ||
                (format && isTagType(currentTag, FORMAT_TAG_TYPE)) ||
                (subcategories && isSubCategory(currentTag))
            ) {
                return '';
            }

            return currentTag;
        }),
    );
};
