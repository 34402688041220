import {
    OPEN_CLOSE_ANIMATED_DRAWER,
    SET_FILTER_TYPE_TO_SHOW,
} from '@eventbrite/search-utils';
import { combineReducers } from 'redux';
import {
    HIDE_ERROR_NOTIFICATION,
    SET_HOVER_EVENT_TARGET,
    SET_MAP_PIN_CLICK_TARGET,
    SET_MAP_PIN_HOVER_TARGET,
    SHOW_ERROR_NOTIFICATION,
} from '../actions/ui';
interface IAction<T> {
    type: string;
    payload?: T;
}

const isAnimatedDrawerOpen = (
    state = false,
    { type, payload }: { type: string; payload?: any },
) => {
    let nextState = state;

    if (type === OPEN_CLOSE_ANIMATED_DRAWER) {
        nextState = payload.open;
    }

    return nextState;
};

const filterTypeToShow = (
    state = '',
    { type, payload }: { type: string; payload?: any },
) => {
    let nextState = state;

    if (type === OPEN_CLOSE_ANIMATED_DRAWER) {
        nextState = payload.filterType || '';
    } else if (type === SET_FILTER_TYPE_TO_SHOW) {
        nextState = payload;
    }

    return nextState;
};

const eventHoverTarget = (
    state = null,
    { type, payload }: { type: string; payload?: any },
) => {
    let nextState = state;

    if (type === SET_HOVER_EVENT_TARGET) {
        nextState = payload;
    }

    return nextState;
};

export type FocusedEventCardsType = string[];

const focusedEventCards = (
    state: FocusedEventCardsType = [],
    action: IAction<string[]>,
) => {
    let nextState = state;

    if (action.type === SET_MAP_PIN_CLICK_TARGET) {
        nextState = action.payload ? action.payload : nextState;
    }

    return nextState;
};

const raisedEventCards = (
    state: FocusedEventCardsType = [],
    action: IAction<FocusedEventCardsType>,
) => {
    let nextState = state;

    if (action.type === SET_MAP_PIN_HOVER_TARGET) {
        nextState = action.payload ? action.payload : nextState;
    }

    return nextState;
};

const showErrorNotification = (
    state = false,
    { type, payload }: { type: string; payload?: any },
) => {
    let nextState = state;

    if (type === SHOW_ERROR_NOTIFICATION) {
        nextState = payload;
    } else if (type === HIDE_ERROR_NOTIFICATION) {
        nextState = false;
    }

    return nextState;
};

export default combineReducers({
    eventHoverTarget,
    isAnimatedDrawerOpen,
    filterTypeToShow,
    showErrorNotification,
    focusedEventCards,
    raisedEventCards,
});
