import {
    CURRENT_FUTURE,
    EventSearch,
    QUALITY,
} from '@eventbrite/discover-utils';
import { HAS_WINDOW } from '@eventbrite/feature-detection';
import { sdkRequest } from '@eventbrite/http';
import type { SearchApiResponse } from '@eventbrite/search-utils';
import { search as searchApi } from '@eventbrite/search-utils';
import { keysCamelToSnake } from '@eventbrite/transformation-utils';
import { $FixMe } from '@eventbrite/ts-utils';
import Cookies from 'cookies-js';
import moment from 'moment-timezone';
import { formatUrl } from 'url-lib';
import { searchOrganicEvents } from '../../api/searchOrganicEvents';
export type { SearchApiResponse };

const searchAutocompleteSuggestionsUrl =
    '/api/v3/destination/search/autocomplete/';

const shouldShowDebugInfo = (
    isStaff: boolean,
    searchQueryFiltered?: {
        bbox?: $FixMe;
        hash?: string;
        clientTimezone?: any;
    },
) => {
    // Backend does not support debug info when deduping is used
    // https://github.com/eventbrite/search_service//blob/f272067cfc357b14742262b135379cc035985a8c/search_service/common/elasticsearch/builders/execute.py#L168
    if (searchQueryFiltered?.hash) {
        return false;
    }

    if (!HAS_WINDOW || !window.location) {
        return false;
    }

    return isStaff && window.location.hash === '#debug';
};

// Check if the client_timezone of the query is correct. If we
// received it from the server, it may not be correct because
// it was guessed with GeoIP rather than browser-side JS.
// There may not be any client_timezone at all, this is currently only
// sent for online searches.
const CLIENT_TIMEZONE_COOKIE_NAME = 'client_timezone';

const repairClientTimezone = (searchQuery: EventSearch) => {
    if (searchQuery.clientTimezone) {
        const momentGuessedTimezone = moment.tz.guess();

        if (
            momentGuessedTimezone &&
            searchQuery.clientTimezone !== momentGuessedTimezone
        ) {
            Cookies.expire(CLIENT_TIMEZONE_COOKIE_NAME);
            Cookies.set(CLIENT_TIMEZONE_COOKIE_NAME, momentGuessedTimezone);

            return {
                ...searchQuery,
                clientTimezone: momentGuessedTimezone,
            };
        }
    }

    return searchQuery;
};

/**
 * Wrapper function for eb-ui discover-specific adaptations to the search API
 * Updates Timezone for cookies and determines if debug info should be shown
 * @returns
 */
export const search = async ({
    searchQuery,
    adSlots,
    experimentOverride,
    isStaff,
    useBff,
}: {
    searchQuery: EventSearch;
    adSlots: number;
    experimentOverride?: $FixMe;
    isStaff?: boolean;
    useBff?: boolean;
}): Promise<SearchApiResponse> => {
    const searchQueryFiltered = repairClientTimezone(searchQuery);

    return await searchApi({
        searchQuery: searchQueryFiltered,
        adSlots,
        experimentOverride,
        debug: shouldShowDebugInfo(isStaff || false, searchQueryFiltered),
        useBff: useBff,
    });
};

interface SearchAutoCompleteParameters {
    placeId?: string;
    onlineEventsOnly?: boolean;
    query?: string;
}

export const searchAutocompleteSuggestions = ({
    placeId,
    onlineEventsOnly,
    query,
}: SearchAutoCompleteParameters) => {
    let queryParams: $FixMe = {
        placeId,
        q: query,
        completionTypes: ['event', 'query'],
        'expand.destination_event': ['primary_venue', 'image'],
    };

    if (onlineEventsOnly) {
        queryParams.onlineEventsOnly = true;
    }

    queryParams = keysCamelToSnake(queryParams);
    return sdkRequest(formatUrl(searchAutocompleteSuggestionsUrl, queryParams));
};

export const searchEventSuggestions = ({
    placeId,
    onlineEventsOnly,
}: {
    placeId: string;
    onlineEventsOnly: boolean;
}) =>
    searchOrganicEvents({
        browse_surface: 'search',
        event_search: {
            places: placeId ? [placeId] : [],
            online_events_only: onlineEventsOnly,
            dates: [CURRENT_FUTURE],
            sort: QUALITY,
            aggs: {
                organizertagsautocomplete_agg: { size: 50 },
                tags: {},
                dates: {},
            },
        },
        'expand.destination_event': [
            'primary_venue',
            'image',
            'ticket_availability',
            'saves',
        ],
    });
