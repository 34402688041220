import { EventSearch } from '@eventbrite/discover-utils';
import { deepKeysToSnake } from '@eventbrite/transformation-utils';
import { $FixMe } from '@eventbrite/ts-utils';
import omit from 'lodash/omit';
import { searchAllEvents } from '../../api/searchAllEvents';
import { OrganicEventSearchApiResponse } from '../../api/searchOrganicEvents';
import { pruneObject } from '../../utils';

interface SearchRequestParameters {
    event_search: EventSearch;
    'expand.destination_event': string[];
    debug?: boolean;
    debug_experiment_overrides?: $FixMe;
}

type SearchMetrics = {
    searchQueryResponseTime: number;
    searchResultCount: number;
    searchResultCountUnattendable: number;
};

export type SearchApiResponse = {
    data: OrganicEventSearchApiResponse;
    metrics?: SearchMetrics;
};

export const search = async ({
    searchQuery,
    adSlots,
    experimentOverride,
    debug,
    useBff = false,
}: {
    searchQuery: EventSearch;
    adSlots: number;
    experimentOverride?: $FixMe;
    debug?: boolean;
    useBff?: boolean;
}): Promise<SearchApiResponse> => {
    // The search API allows either a bbox or a WOF placeID but not both
    const searchQueryFiltered =
        searchQuery.bbox && searchQuery.places
            ? omit(searchQuery, 'places')
            : searchQuery;

    const isPointRadiusActive = !!Object.keys(searchQueryFiltered.pointRadius)
        .length;

    let requestParameters: SearchRequestParameters = {
        event_search: deepKeysToSnake(
            pruneObject({
                ...searchQueryFiltered,
                dedup: true,
                page_size: 20,
                ...(isPointRadiusActive ? { places: [] } : {}),
            }),
        ),
        'expand.destination_event': [
            'primary_venue',
            'image',
            'ticket_availability',
            'saves',
            'event_sales_status',
            'primary_organizer',
            'public_collections',
        ],
    };

    if (debug) {
        requestParameters = {
            ...requestParameters,
            debug: true,
        };
    }

    if (
        experimentOverride &&
        experimentOverride.experimentName &&
        experimentOverride.experimentName !== 'none'
    ) {
        requestParameters = {
            ...requestParameters,
            debug_experiment_overrides: {
                [experimentOverride.experimentName]: experimentOverride.variant,
            },
        };
    }

    const apiCallStartTime = new Date().getTime();

    const data = await searchAllEvents({
        ...(requestParameters as any),
        browse_surface: 'search',
        interfaceName: 'search',
        slots: adSlots,
        useBff,
    });

    const searchQueryResponseTime = new Date().getTime() - apiCallStartTime;

    return {
        data,
        metrics: {
            searchQueryResponseTime,
            searchResultCount: data.events.results.length || 0,
            searchResultCountUnattendable: countUnattendableEvents(data),
        },
    };
};

export function countUnattendableEvents({
    events: { results = [], promoted_results = [] },
}: OrganicEventSearchApiResponse) {
    const events = [...results, ...promoted_results];
    const unattendableEvents = events.filter((event) => {
        const isNotOnSale =
            event.event_sales_status?.sales_status !== 'on_sale';

        // Series events should always be considered attendable
        const isNotRecurrent = !event.num_children || event.num_children <= 1;

        return isNotOnSale && isNotRecurrent;
    });

    return unattendableEvents.length;
}
