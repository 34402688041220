import { gettext } from '@eventbrite/i18n';

export const getLocationInfo = (venueName?: string, venueAddress?: string) => {
    if (venueAddress && venueName) {
        //ex. Eventbrite • San Francisco CA
        return gettext('%(venueName)s \u2022 %(venueAddress)s', {
            venueName,
            venueAddress,
        });
    }

    if (Boolean(!venueName) && Boolean(!venueAddress)) {
        return '';
    }

    return venueName || venueAddress;
};
