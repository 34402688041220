import { combineReducers } from 'redux';

const artistSummary = (state = '') => {
    const nextState = state;

    return nextState;
};

const artistImageUrl = (state = '') => {
    const nextState = state;

    return nextState;
};

const artistName = (state = '') => {
    const nextState = state;

    return nextState;
};

const headerImageUrl = (state = '') => {
    const nextState = state;

    return nextState;
};

const headerSmallImageUrl = (state = '') => {
    const nextState = state;

    return nextState;
};

const middleImageUrl = (state = '') => {
    const nextState = state;

    return nextState;
};

const middleSmallImageUrl = (state = '') => {
    const nextState = state;

    return nextState;
};

const footerImageUrl = (state = '') => {
    const nextState = state;

    return nextState;
};

const footerSmallImageUrl = (state = '') => {
    const nextState = state;

    return nextState;
};

const decorationTopLeftUrl = (state = '') => {
    const nextState = state;

    return nextState;
};

const decorationTopRightUrl = (state = '') => {
    const nextState = state;

    return nextState;
};

const decorationBottomLeftUrl = (state = '') => {
    const nextState = state;

    return nextState;
};

const decorationBottomRightUrl = (state = '') => {
    const nextState = state;

    return nextState;
};

const artistSocialLinks = (state = []) => {
    const nextState = state;

    return nextState;
};

export default combineReducers({
    headerImageUrl,
    headerSmallImageUrl,
    middleImageUrl,
    middleSmallImageUrl,
    footerImageUrl,
    footerSmallImageUrl,
    artistSummary,
    artistImageUrl,
    artistName,
    decorationTopLeftUrl,
    decorationTopRightUrl,
    decorationBottomLeftUrl,
    decorationBottomRightUrl,
    artistSocialLinks,
});
