import { sdkRequest } from '@eventbrite/http';

export type TrendingSearchesResponseBody = {
    trending: {
        trending_searches: TrendingSearches;
        active_timeframe: string;
    };
};

export type TrendingSearches = {
    formatted: string;
    score: number;
    term: string;
};

/**
 * Fetch Trending Searches for a location.
 *
 * @param location the location for which to fetch trending searches
 * @param timeFrame the timeFrame for which to fetch trending searches
 */
export const getTrendingSearches = async (
    location: string,
    timeframe = 'month',
): Promise<TrendingSearchesResponseBody> => {
    return sdkRequest(
        `/api/v3/trending/?location=${location}&timeframe=${timeframe}`,
        { method: 'GET' },
    );
};
