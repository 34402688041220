import { LocationState } from '@eventbrite/discover-utils';
import { locationDuck } from '@eventbrite/redux-destination';
import { combineReducers } from 'redux';

const {
    reducersByKey: {
        suggestions,
        slug,
        placeId,
        latitude,
        longitude,
        currentPlace,
        currentPlaceParent,
        country,
        countrySlug,
        placeType,
        isWaitingForLocation,
        isUsingCurrentLocation,
        isOnline,
        locationDenied,
    },
    actionTypes: { UPDATE_LOCATION },
} = locationDuck;

const fallBack = (
    state = null,
    { payload, type }: { payload?: any; type?: string },
) => {
    let nextState = state;

    if (type === 'SET_FALLBACK_LOCATION') {
        nextState = payload;
    } else if (type === UPDATE_LOCATION && !payload.isOnline) {
        nextState = null;
    }

    return nextState;
};

const userLatLng = (
    state: GeolocationCoordinates | null = null,
    { payload, type }: { payload?: any; type?: string },
) => {
    let nextState = state;

    if (type === 'UPDATE_LOCATION') {
        nextState = payload.userLatLng ?? null;
    } else if (type === 'UPDATE_LOCATION' && !payload?.isUsingCurrentLocation) {
        nextState = null;
    }

    return nextState;
};

export default combineReducers<LocationState>({
    fallBack,
    suggestions,
    slug,
    latitude,
    longitude,
    placeId,
    placeType,
    isWaitingForLocation,
    isUsingCurrentLocation,
    isOnline,
    locationDenied,
    currentPlace,
    currentPlaceParent,
    country,
    countrySlug,
    topSuggestions: (state = []) => state,
    trendingSearchCities: (state = []) => state,
    cityBlurb: (state = '') => state,
    currentLocationCoordinates: (state = {}) => state,
    h1Prefix: (state = '') => state,
    city: (state = '') => state,
    citySlug: (state = '') => state,
    borough: (state = '') => state,
    boroughSlug: (state = '') => state,
    userLatLng,
});
