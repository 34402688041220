import { DEFAULT_CURRENCY_FORMAT } from '@eventbrite/intl';
import { combineReducers } from 'redux';
import { DiscoverState } from '../../types';
import adminReducer from './admin';
import appReducer from './app';
import authReducer from './auth';
import brandReducer from './brand';
import contentReducer from './content';
import locationReducer from './location';
import { previousUrlContextReducer } from './previousUrlContext';
import searchReducer from './search';
import shareReducer from './share';
import suggestionsReducer from './suggestions';
import thingsToDoShelfReducer from './thingsToDoShelf';
import trendingSearchesReducer from './trendingSearches';
import uiReducer from './ui';

export default combineReducers<DiscoverState>({
    // routing: d=>d,
    location: locationReducer,
    content: contentReducer,
    brand: brandReducer,
    auth: authReducer,
    share: shareReducer,
    search: searchReducer,
    suggestions: suggestionsReducer,
    app: appReducer,
    currencyFormat: (state = DEFAULT_CURRENCY_FORMAT) => state,
    botH1: (state = '') => state,
    googleMapsApiKey: (state = '') => state,
    user: (state = {}) => state,
    admin: adminReducer,
    ui: uiReducer,
    shouldShowTrendingSearchPageLink: (state = false) => state,
    previousUrlContext: previousUrlContextReducer,
    thingsToDoShelf: thingsToDoShelfReducer,
    pointOfInterestShelf: (state = {}) => state,
    trendingSearchesResult: trendingSearchesReducer,
});
