import { CoreApplicationContext } from '@eventbrite/context-gen';
import { parseSuggestions, PlaceObject } from '@eventbrite/discover-utils';
import { parseLocationResponse } from '@eventbrite/redux-destination';
import {
    getCurrentNormalizedUrl,
    getInitialSearchCriteria,
} from '@eventbrite/search-utils';
import { StatsigOptions } from '@eventbrite/statsig';
import { $FixMe } from '@eventbrite/ts-utils';
import { StringifyQuery } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import {
    ApiSearchData,
    Branding,
    DiscoverState,
    FacebookOptions,
    FeatureFlags,
    PointOfInterestShelfState,
    PopularCities,
    ThingsToDoShelfState,
    TrendingSearchesResultState,
} from '../../types';
import { getPageDescription } from '../../utils/general';
import { transformBrandingResponse } from '../../utils/transformation';
export { getInitialSearchCriteria };

type GetInitialDataProps = RouteComponentProps & {
    placeId?: string;
    latitude?: number;
    longitude?: number;
    description?: string;
    isBotRequest?: boolean;
    facebookOptions?: FacebookOptions;
    featureFlags?: FeatureFlags;
    currentPlace?: string;
    country?: string;
    countrySlug?: string;
    city?: string;
    citySlug?: string;
    borough?: string;
    boroughSlug?: string;
    buckets?: any;
    currentPlaceParent?: string;
    branding?: Branding;
    locations?: {
        places: PlaceObject[];
    };
    suggestions?: $FixMe;
    experiments?: any;
    guest_id?: string;
    location_slug?: string;
    search_id?: string;
    search_data?: ApiSearchData;
    all_events_url?: string;
    http_referer?: StringifyQuery;
    trending_search_cities?: string[][];
    current_location_coordinates?: { latitude: number; longitude: number };
    place_type?: string;
    user: {
        isAuthenticated?: boolean;
        publicId?: string;
        email?: string;
        isStaff?: boolean;
    };
    request: { session_id: string };
    env: CoreApplicationContext['env'];
    category_article_mapping?: $FixMe;
    h1_prefix?: string;
    is_online?: boolean;
    botH1?: string;
    google_maps_api_key?: string;
    should_show_trending_search_page_link?: boolean;
    isGDPRCountry?: boolean;
    options?: StatsigOptions;
    country_popular_cities?: PopularCities;
    things_to_do_shelf?: ThingsToDoShelfState;
    trendingSearchesResult?: TrendingSearchesResultState;
    point_of_interest_shelf?: PointOfInterestShelfState;
};

// TODO: This functions should return a complete redux state, but currently it's returning only a partial state
// Remaining parts of the redux state are populated on the initial reducer passthrough, but this is an antipattern
export const getInitialData = (props: GetInitialDataProps): DiscoverState => {
    const {
        history,
        placeId = '',
        latitude = 0,
        longitude = 0,
        description,
        isBotRequest,
        facebookOptions,
        featureFlags,
        currentPlace = '',
        buckets,
        currentPlaceParent = '',
        branding = {},
        locations = {},
        suggestions = {},
        experiments = {},
        guest_id: guestId,
        location_slug: slug = '',
        search_id: searchId = '',
        search_data: searchData,
        all_events_url: allEventsUrl,
        http_referer: httpReferer,
        trending_search_cities: trendingSearchCities = [],
        current_location_coordinates: currentLocationCoordinates = {},
        place_type: placeType = '',
        country,
        countrySlug,
        city,
        citySlug,
        borough,
        boroughSlug,
        user: { isAuthenticated, publicId, email: userEmail, isStaff },
        request: { session_id: sessionId },
        env: {
            isMobile,
            localeInfo: {
                // every locale can use a different twitter account and
                // facebook account, so we need to use them in order to build
                // share messages
                twitter_handle: twitterHandle,
                facebook_locale: facebookLocale,
                facebook_page: facebookPage,
                google_maps_language: googleMapsLanguage,
                country_code: countryCode,
                language_code: languageCode,
                tld,
                locale,
                uses_language_subdirectory,
            },
            serverUrl,
            currencyFormat,
            ebDomain: domain,
        },
        category_article_mapping: categoryArticleMapping,
        h1_prefix: h1Prefix = '',
        is_online: isOnline = false,
        botH1,
        google_maps_api_key: googleMapsApiKey,
        should_show_trending_search_page_link: shouldShowTrendingSearchPageLink,
        isGDPRCountry,
        options,
        country_popular_cities,
        things_to_do_shelf: thingsToDoShelf,
        point_of_interest_shelf: pointOfInterestShelf,
        trendingSearchesResult,
    } = props;

    const eventSearch = getInitialSearchCriteria(searchData);

    const cityBlurb =
        description || getPageDescription(currentPlace || '', buckets);

    const location = {
        currentPlace,
        currentPlaceParent,
        cityBlurb,
        country,
        countrySlug,
        city,
        citySlug,
        borough,
        boroughSlug,
        placeId,
        latitude,
        longitude,
        trendingSearchCities,
        slug,
        placeType,
        currentLocationCoordinates,
        topSuggestions: parseLocationResponse(locations),
        h1Prefix,
        isOnline,
    };

    const search = {
        eventSearch,
        searchId,
        searchActionToLog: '',
    };

    const url = getCurrentNormalizedUrl(history);
    return {
        currencyFormat,
        auth: {
            isAuthenticated,
        },
        location,
        content: {
            allEventsUrl,
            categoryArticleMapping,
        },
        share: {
            options: {
                isMobile,
                twitterHandle,
                serverUrl,
                facebookOptions: {
                    ...facebookOptions,
                    locale: facebookLocale,
                    page: facebookPage,
                },
            },
        },
        brand: transformBrandingResponse(branding, isOnline),
        suggestions: parseSuggestions(suggestions),
        search,
        admin: {
            experimentOverride: {
                experimentName: Object.keys(experiments)[0],
                variant: experiments[Object.keys(experiments)[0]],
            },
        },
        app: {
            isBotRequest,
            sessionId,
            publicId,
            countryCode,
            googleMapsLanguage,
            httpReferer,
            domain,
            featureFlags,
            experiments,
            serverUrl,
            guestId,
            userEmail,
            languageCode,
            isGDPRCountry,
            tld,
            options,
            locale,
            uses_language_subdirectory,
            country_popular_cities,
        },
        botH1,
        googleMapsApiKey,
        user: {
            isStaff,
        },
        shouldShowTrendingSearchPageLink,
        ui: undefined,
        previousUrlContext: {
            [url]: {
                location,
                search,
                thingsToDoShelf,
                trendingSearchesResult,
            },
        },
        thingsToDoShelf,
        pointOfInterestShelf,
        trendingSearchesResult,
    } as $FixMe;
};
