import { LocationState } from '@eventbrite/discover-utils';
import {
    SearchState,
    ThingsToDoShelfState,
    TrendingSearchesResultState,
} from '../../types';
import { STORE_EVENT_SEARCH_AND_LOCATION_BY_URL } from '../actions/previousUrlContext';

export type PreviousUrlContextState = Record<
    string,
    {
        search: SearchState;
        location: LocationState;
        thingsToDoShelf: ThingsToDoShelfState;
        trendingSearchesResult: TrendingSearchesResultState;
    }
>;

export const previousUrlContextReducer = (
    state = {},
    { type, payload }: { type: string; payload?: any },
) => {
    if (type === STORE_EVENT_SEARCH_AND_LOCATION_BY_URL) {
        const {
            url,
            search,
            location,
            thingsToDoShelf,
            trendingSearchesResult,
        } = payload;

        return {
            ...state,
            [url]: {
                search,
                location,
                thingsToDoShelf,
                trendingSearchesResult,
            },
        };
    }

    return state;
};
