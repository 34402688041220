export const SET_EXPERIMENT_OVERRIDE = 'SET_EXPERIMENT_OVERRIDE';
export const SET_SHOULD_SHOW_DEBUG_OUTPUT = 'SET_SHOULD_SHOW_DEBUG_OUTPUT';

export const setExperimentOverride = (experimentOverride: any) => ({
    type: SET_EXPERIMENT_OVERRIDE,
    payload: experimentOverride,
});

export const setShouldShowDebugOutput = (shouldShowDebugOutput?: boolean) => ({
    type: SET_SHOULD_SHOW_DEBUG_OUTPUT,
    payload: shouldShowDebugOutput,
});
