import includes from 'lodash/includes';

import {
    ALL_EVENTS_TYPE,
    CURRENT_FUTURE,
    CUSTOM_DATE,
    EVENTS_TYPE,
    PointRadius,
} from '@eventbrite/discover-utils';
import { $FixMe } from '@eventbrite/ts-utils';
import uniq from 'lodash/uniq';
import { StringFlag } from 'meow';
import { CLEAR_PRICE_FILTER } from '../../../constants';
import { updateSearchCriteria } from '../../search';
import { getUpdatedTags } from '../../utils';

export type handleApplyFilterParams = {
    category?: string;
    subcategories?: string[];
    price?: string;
    dates?: string;
    q?: string;
    format?: string;
    languages?: any;
    currencies?: string;
    special?: string;
    hash?: string;
    distance?: string;
    experiences?: string[];
    dateRange?: { startDate?: string; endDate?: string };
};
type getTrackingData = {
    getTrackingData: (selectedFilter?: object) => string;
};
type baseHandleApplyFilterParams = handleApplyFilterParams & getTrackingData;

export const baseHandleApplyFilter =
    (params: baseHandleApplyFilterParams) =>
    (dispatch: Function, getState: Function) => {
        const {
            category,
            subcategories,
            price,
            dates,
            q,
            format,
            languages,
            currencies,
            special,
            hash,
            distance,
            experiences,
            getTrackingData,
            dateRange = {},
        } = params;
        const { startDate, endDate } = dateRange;
        const {
            search: { eventSearch },
            location,
        } = getState();
        const searchActionToLog = getTrackingData({
            category,
            subcategories,
            price,
            dates,
            q,
            format,
            languages,
            currencies,
            special,
        });

        const updateSpecialParams = _getSpecialParams(
            eventSearch.special,
            special,
        );
        const updatedTags = getUpdatedTags(
            eventSearch.tags,
            subcategories,
            category,
            format,
        );
        const updatedPrice = _handlePriceUpdate(price || '', eventSearch.price);
        const updatedDates = _getUpdatedDates(dates || '', eventSearch.dates);
        const updatedCurrencies = _getUpdatedArrayFilter(
            currencies || '',
            eventSearch.currencies,
        );
        const updatedLanguages = languages || eventSearch.languages;
        const updatedDateRange = _getUpdatedDateRange(
            dates || '',
            startDate,
            endDate,
            eventSearch.dateRange,
        );
        const updatedHash = hash || eventSearch.hash;
        const updatedPointRadius = _getUpdatedPointRadius(
            eventSearch.pointRadius,
            distance,
            location,
        );
        const updatedExperiences = experiences || eventSearch.experiences;

        let searchQuery = {
            ...eventSearch,
            q: !(!q && q !== '') ? q : eventSearch.q,
            price: updatedPrice,
            tags: updatedTags,
            dates: updatedDates,
            dateRange: updatedDateRange,
            currencies: updatedCurrencies,
            languages: updatedLanguages,
            special: updateSpecialParams,
            hash: updatedHash,
            pointRadius: updatedPointRadius,
            bbox: distance ? '' : eventSearch.bbox,
            page: 1,
            experiences: updatedExperiences,
        };

        // when searching for 'events' or 'all events' filters should be resetted
        if (
            includes(
                [EVENTS_TYPE, ALL_EVENTS_TYPE],
                (searchQuery.q || '').toLowerCase(),
            )
        ) {
            searchQuery = {
                ...eventSearch,
                q: '',
                price: '',
                category: '',
                tags: [],
                dates: CURRENT_FUTURE,
                dateRange: '',
                page: 1,
                currencies: '',
                languages: '',
                pointRadius: {},
                experiences: [],
            };
        }

        dispatch(updateSearchCriteria(searchQuery, searchActionToLog));
    };

const _getUpdatedPointRadius = (
    pointRadiusInState: PointRadius,
    distance?: string,
    location?: $FixMe,
) => {
    if (distance) {
        //If user is already using their current location,
        //the location will be embedded in pointRadiusInState
        //If not we must grab it from location in state.
        return {
            radius: distance,
            offset: undefined,
            scale: undefined,
            latitude: pointRadiusInState?.latitude || location.latitude,
            longitude: pointRadiusInState?.longitude || location.longitude,
        };
    }

    return pointRadiusInState;
};

const _getUpdatedDates = (dates: string, datesInState: string) => {
    if (dates === CUSTOM_DATE) {
        return CURRENT_FUTURE;
    } else if (dates) {
        return [CURRENT_FUTURE, dates];
    }
    return datesInState;
};

const _handlePriceUpdate = (price: string, priceInState: string) => {
    let priceValue = priceInState;

    if (price) {
        priceValue = price === CLEAR_PRICE_FILTER ? '' : price;
    }

    return priceValue;
};

const _getUpdatedDateRange = (
    dates: string,
    startDate?: string,
    endDate?: string,
    dateRangeInState?: StringFlag,
) => {
    if (startDate && endDate) {
        return { from: startDate, to: endDate };
    }
    if (dates) {
        return {};
    }
    return dateRangeInState;
};

const _getUpdatedArrayFilter = (filter: string, filterInState: string) => {
    if (filter) {
        if (typeof filter === 'string') {
            return [filter];
        } else {
            return filter;
        }
    }

    return filterInState;
};
const _getSpecialParams = (filterInState: string, filter?: string) => {
    if (!filter) {
        return filterInState;
    }

    return uniq([...filterInState, filter]);
};
