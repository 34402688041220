import { isCategory } from '@eventbrite/categories';
import {
    getTransformedContent,
    SuggestionsState,
} from '@eventbrite/discover-utils';
import has from 'lodash/has';

export const transformSearchIntoSuggestions = (
    response = {},
): SuggestionsState => {
    const { result } = getTransformedContent(
        'aKeyThatDoesNotMatterInThisContext',
        {
            response,
        },
    );

    let events = [];
    let tags = [];
    let filters = [];

    if (has(result, 'response.events.results')) {
        events = result.response.events.results;
    }

    if (
        has(result, 'response.events.aggs.organizertagsautocompleteAgg.buckets')
    ) {
        const aggregatedTags =
            result.response.events.aggs.organizertagsautocompleteAgg.buckets;

        const filteredTags = aggregatedTags.filter(
            ({ docCount }: { docCount: number }) => docCount > 5,
        );

        tags = filteredTags.map(
            (tag: { key: string; docCount: number; displayName: string }) => ({
                value: tag.key,
                count: tag.docCount,
                display: tag.displayName,
            }),
        );
    }

    if (has(result, 'response.events.aggs.tags.buckets')) {
        const aggregatedFilters = result.response.events.aggs.tags.buckets;
        const filteredFilters = aggregatedFilters.filter(
            ({ docCount, key }: { docCount: number; key: string }) =>
                docCount > 5 && !key.includes('Sub'),
        );
        filters = filteredFilters.map(
            (filter: { key: string; docCount: number }) => ({
                value: filter.key,
                count: filter.docCount,
                type: isCategory(filter.key) ? 'category' : 'format',
            }),
        );
    }

    if (has(result, 'response.events.aggs.dates.buckets')) {
        const aggregatedDates = result.response.events.aggs.dates.buckets;
        const filteredDates = aggregatedDates.filter(
            ({ docCount, key }: { docCount: number; key: string }) =>
                docCount > 5 && key === 'this_weekend',
        );
        filters = [
            ...filters,
            ...filteredDates.map((date: { key: string; docCount: number }) => ({
                value: date.key,
                count: date.docCount,
                type: 'dates',
            })),
        ];
    }

    return {
        events,
        tags,
        filters,
    };
};
