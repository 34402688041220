import { type GenericLazyString } from '@eventbrite/i18n';
import React from 'react';

interface BreadCrumbListItemContentProps {
    isLast: boolean;
    display: React.ReactElement | string | GenericLazyString;
    shouldIncludeMicroData: boolean;
}

/**
 * If isLast is `true` will render a span
 * with the display content rendered directly.
 *
 * Otherwise will render its children which calls
 * a render prop to inverse control to the calling
 * application.
 *
 * @param props
 * @returns ReactElement
 */
export const BreadCrumbListItemContent = (
    props: React.PropsWithChildren<BreadCrumbListItemContentProps>,
) => {
    if (props.isLast) {
        return (
            <span
                {...(props.shouldIncludeMicroData
                    ? {
                          itemProp: 'name',
                      }
                    : {})}
                aria-current="page"
                data-testid="breadcrumb-final-element"
            >
                {props.display}
            </span>
        );
    }

    return <>{props.children}</>;
};
