import { connect } from 'react-redux';
import { BasePage } from '../../pages/base';
import { selectSearchBarProps } from '../../redux/selectors/location';
import { DiscoverState } from '../../types';

const _mapStateToProps = (state: DiscoverState, ownProps: any) => {
    return {
        ...ownProps,
        searchBar: selectSearchBarProps(state),
    };
};

export default connect(_mapStateToProps)(BasePage);
