import { UPDATE_CONTENT } from '@eventbrite/discover-utils';
import { combineReducers } from 'redux';
import { UPDATE_EVENT_SAVED_BY_YOU } from '../actions/event';

const entityContext = (
    state = [],
    { type, payload }: { type: string; payload?: any },
) => {
    let nextState = state;

    if (type === UPDATE_CONTENT) {
        nextState = payload.entityContext;
    }

    return nextState;
};

const entities = (
    state = {},
    { type, payload }: { type: string; payload?: any },
) => {
    let nextState: { [key: string]: any } = state;

    if (type === UPDATE_EVENT_SAVED_BY_YOU) {
        const { eventId, savedByYou } = payload;

        nextState = {
            ...nextState,
            [eventId]: {
                ...nextState[eventId],
                saves: {
                    savedByYou,
                },
            },
        };
    } else if (type === UPDATE_CONTENT) {
        nextState = {
            ...nextState,
            ...payload.entities,
        };
    }

    return nextState;
};

const allEventsUrl = (state = '') => {
    const nextState = state;

    return nextState;
};

const categoryArticleMapping = (state = {}) => {
    const nextState = state;

    return nextState;
};

export default combineReducers({
    entityContext,
    entities,
    allEventsUrl,
    categoryArticleMapping,
});
