import { CURRENT_FUTURE } from '@eventbrite/discover-utils';
import { updateSearchCriteria } from '../../search';

export const handleRemoveAllFilters = () => (dispatch: Function) => {
    // Since we want to remove only the filters in the filter panel,
    // we don't want to spread ...eventSearch since that would reset
    // the q as well
    const searchQuery = {
        tags: [],
        price: undefined,
        dates: CURRENT_FUTURE,
        dateRange: {},
        currencies: '',
        languages: '',
        page: 1,
        pointRadius: {},
    };

    dispatch(updateSearchCriteria(searchQuery));
};
